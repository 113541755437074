<template>
    <div class="fixed left top pa-0 fill-height">
      <video autoplay playsinline ref="video" class="relative top left black"></video>
      <div class="pa-4 absolute bottom left fill-width pb-12 text-center">
        <!-- canvas somehow needed -->
        <canvas ref="canvas"></canvas>

        <!-- base64 -->
        <div v-for="(img, index) in photos"
          :key="'snap-'+index"
          class="mb-2"
        >
          <v-img 
            :src="img.imageContent"
            width="12.5%"
            height="12.5%"
            class="rounded grey-border grey"
            cover
            :class="img.uploaded ? '' : 'op-75'"
            aspect-ratio="1"
          >
            <v-row
              v-if="!img.uploaded"
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
        </v-img>
        </div>

        <div class="code" v-if="error">error: {{ error }}</div>
        <div class="code" v-if="devices">devices: {{ devices }}</div>
        <div class="code" v-if="info">info: {{ info }}</div>

       
          <!-- <v-btn @click="snap()" class="mx-6 primary" large icon>
            <v-icon color="">mdi-camera</v-icon>
          </v-btn> -->
          <v-btn @click="snapAsDataUrl()" class="mr-4 green elevation-8" large icon>
            <v-icon>mdi-camera</v-icon>64
          </v-btn>
          <v-btn @click="switchCamera()" class="mx-8 secondary ginormous elevation-8" icon>
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
          <v-btn @click="snapAsBlob()" class="mx-8 primary ginormous elevation-8" icon>
            <v-icon>mdi-camera</v-icon>
          </v-btn>
          <v-btn @click="finish()" class="ml-4 black elevation-8" large icon>
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-btn>
      </div>
      <!-- <br>
      <br>
      <br>
      <br>
       <v-btn @click="stopCamera()" color="red" class="ml-4">Stop Camera</v-btn>
      <v-btn @click="startCamera()" color="primary" class="ml-4">Start Camera</v-btn> -->
    </div>
</template>

<script>
import { storage } from '../firebase'
// import Props from "../components/Props.vue"
// import BrightnessSwitcher from "./BrightnessSwitcher.vue"
//import Camera from 'easy-js-camera';  // from npm
import Camera from '../../lib/easy-js-camera';  // customized one!

export default {
  name: 'CAMERA',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    menu: Boolean,
  },
  components: {
    // Props,
  },
  data() {
    return {
        camera: null,
        error: null,
        photos: [],
        devices: null,
        info: null,
    }
  },
  created() {
  },
  mounted() {
    this.startCamera();
  },

  methods: {
    async startCamera() {
      // Create a new Camera instance and start it
      /* this.camera = new Camera(document.getElementById('camera-view'));
      this.camera.start(); */

      var video = this.$refs.video;
      var canvas = this.$refs.canvas;

      // this.info = Camera.isCameraSupported();

      await Camera
        .tryInvokePermission(video, canvas)
        .then(camera => {
            // this.devices = camera.getDevices();
            camera.start();
            this.camera = camera;
        })
        .catch(error => {
            // Mostly happens if the user blocks the camera or the media devices are not supported
            console.log(":( ", error)
            this.error = error;
        });

      // start with back camera on mobile
      this.switchCamera();
    },

    stopCamera() {
      // Stop the camera when not needed
      if (this.camera) {
        this.camera.stop();
      }
    },

    snap() {
        this.camera.snap();
    },

    snapAsDataUrl() {
      let base64 = this.camera.snapAsDataUrl();
      this.photos.push({id: this.photos.length, imageContent: base64, uploaded:false});
      let now = this.$moment(new Date()).format("YYYY-MM-DD_HH-mm-ss");
      this.uploadData({name: `${now}-photo-base64-${this.user.name}.jpg`, base64: base64}, 'props','testPhoto', this.photos.length);
    },

    async snapAsBlob() {
      let blob = await this.camera.snapAsBlob();
      this.photos.push({id: this.photos.length, imageContent: URL.createObjectURL(blob), uploaded:false});
      let now = this.$moment(new Date()).format("YYYY-MM-DD_HH-mm-ss");
      this.uploadData({name: `${now}-photo-blob-${this.user.name}.png`, blob: blob}, 'props','testPhoto', this.photos.length);
    },

    switchCamera(tryAgain=false) {
    this.camera.switch(tryAgain)
        .catch(() => {
            if(tryAgain) return; // This line prevents loops. Because the tryAgain may also fail
            this.camera.switch(true);
        });
    },

    // eslint-disable-next-line no-unused-vars
    uploadAll(bucket, propId, base64) {
      /* for (let i = 0; i < event.target.files.length; i++) {
        this.uploadData(event.target.files[i], event.target.files.length-i);
      } */
    },

    // async uploadData(fileData, order) {
    async uploadData(fileData, bucket, propId, order) {

      /* if(this.maxImageSizeLocal > 0) {
        this.resizing = true;  // display icon in GUI
        // Get image sizes and resize if necessary
        fileData = await this.processImage(fileData);
        this.resizing = false;  // display icon in GUI
      } */

      // Set default img data - should come from processImage() above!
      fileData.type = 'image/png';
      fileData.imageType = 'image';
      fileData.width = 0;
      fileData.height = 0;

      // Create path with filename but with additional random ID to allow dulicate names of files
      let path = [bucket, propId, fileData.name].join("/");
      
      // Save file to db, either original or from resized blob
      let currentStorageRef =  storage.ref(path).put(fileData.blob ? fileData.blob : fileData.base64);
      // const storageRef = uploadBytes(path).put(fileData);
      // eslint-disable-next-line no-unused-vars
      currentStorageRef.on(`state_changed`, (snapshot) => {
          /* this.totalBytes = snapshot.totalBytes;
          // Increment progress for one file
          if(this.totalFiles === 1) {
            this.progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          } */
        },(error) => {
          console.log(error.message);
          this.$emit('error', error.message)
        },() => {
          //if(this.totalFiles === 1) {
            //this.progress = 100;
            console.log('finished');
            this.photos[order-1].uploaded = true;
         // }
          currentStorageRef.snapshot.ref.getDownloadURL().then((url) => {
            console.log("halleluja! ", fileData, order, path, url);
            // this.finalizeUploadOfFile(fileData, order, path, url);
          });
        }
      );
    },

    finish() {},

  },
  beforeDestroy() {
    // Make sure to stop the camera when the component is destroyed
    this.stopCamera();
  },
  watch: {
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },
};
</script>

<style scoped>
canvas {
  display: none;
  /* width:3333px;
  height:3333px; */
}

video {
  width:100%;
  height:100%;
  /* width:3333px;
  height:3333px; */
  object-fit: cover;
  object-position: center center;
}
</style>