<template>
  <div :class="classMainContainer">
    <v-container fluid class="pa-0">
      <v-row class="ma-0 pa-0" md>
        <v-col
          v-for="(item, index) in languagesToShow"
          :key="`${item}-${index}`"
          :cols="oneLineBut ? Math.floor(parseInt(12 / $i18n.availableLocales.length)) : 12"
          class="py-0 px-2"
          :class="oneLineBut ? index === 0 ? 'pl-0' : index ===  $i18n.availableLocales.length-1 ? 'pr-0': '' : 'pl-0 pr-0'"
        >
          <v-textarea
            v-model.trim="localData[item]"
            :tabindex="tabindexLocal ? tabindexLocal+index : null"
            type="text"
            filled
            :full-width="false"
            auto-grow
            :dense="dense"
            :rows="initialRowHeight"
            :class="classNames"
            :prefix="prefix === 'item' ? showAll ? `${item.toUpperCase()}:` : '' : prefix"
            :label="$t(label)"
            :hint="$t(hint)"
            :placeholder="$t(placeholder)"
            :hide-details="hideDetails"
            :prepend-icon="prependIcon"
            :prepend-inner-icon="prependInnerIcon"
            :append-outer-icon="showAll && index === $i18n.availableLocales.length-1 ? 'mdi-format-horizontal-align-center' : !showAll && index === 0 ? 'mdi-arrow-expand-horizontal' : appendOuterIcon"
            @click:append-outer="showAll = !showAll"
            :loading="loading"
            >
            <template v-slot:append>
              <v-icon color="secondary" @click="showAll = true; autoTranslate(localData, localData[item], item)">{{appendIcon ? appendIcon : 'mdi-translate'}}</v-icon>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
<script>
export default {
  props: {
    auth: Boolean,
    edit: Boolean,
    user: Object,
    triggerTranslations: String,
    data: Object,
    classNames: String,
    classMainContainer: String,
    dense: Boolean,
    initialRowHeight: String,
    label: String,
    hint: String,
    hideDetails: String,
    oneLine: Boolean,
    prefix: String,
    placeholder: String,
    showAllLanguages: Boolean,
    appendIcon: String,
    appendOuterIcon: String,
    prependInnerIcon: String,
    prependIcon: String,
    tabindex: String,
  },
  components: {
    // IconSelector
  },
  data() {
    return {
      localData: {},
      showAll: false,
      loading: false,
      tabindexLocal: 0,
    };
  },

  mounted() {
  },
  
  created() {
    this.localData = this.data;
    this.showAll = this.showAllLanguages;
    this.tabindexLocal = parseInt(this.tabindex);
  },

  beforeDestroy() {
  },

  computed: {
    languagesToShow() {
      return this.showAll ? this.$i18n.availableLocales : [this.$i18n.locale];
    },
    oneLineBut() {
      return this.showAll ? this.oneLine : false;
    },
  },

  methods: {
    async autoTranslate(sourceData, text, sourceLang) {
      this.loading = true;
      let translation = "";
      let languages = this.$i18n.availableLocales;
      if(text) {
        // Text was provided - clicked on textbox that had text in it
        for(let i = 0; i < languages.length; i++){
          if(sourceLang !== languages[i]) {
            // Commented to overwrite fields that have text with new translation
            // if(!sourceData[languages[i]]) {
              translation = await this.$store.dispatch('translate', {text: text, lang: languages[i]});
              sourceData[languages[i]] = translation[0].text;
              // this.$emit('translated', true);
            // }
          }
        }
      } else {
        // Text was not provided - clicked on textbox that was empty
        // eslint-disable-next-line no-unused-vars
        const firstNonEmpty = Object.entries(sourceData).find(([key, value]) => value !== "");
        // nothing was enterd
        if(!firstNonEmpty) {
          this.loading = false;
          this.$emit('translated', false);  // true
          // this.$emit('translated', false);
          return;
        }
        let filledKey = firstNonEmpty[0];
        let translate = firstNonEmpty[1];  // translate
        for(let i = 0; i < languages.length; i++){
          if(languages[i] !== filledKey) {
            if(!sourceData[languages[i]]) {
              translation = await this.$store.dispatch('translate', {text: translate, lang: languages[i]});
              sourceData[languages[i]] = translation[0].text;
            }
          }
        }
      }
      this.$emit('translated', true);
      this.loading = false;
      return;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    triggerTranslations: function(oldValue, newValue) {
      if(oldValue !== newValue) {
        this.autoTranslate(this.localData, "", "");
      } else {
        this.$emit('translated', false);  // true
      }
    },
    data: {
      handler: function(oldValue, newValue) {
        if(oldValue !== newValue) {
          this.localData = this.data;
          this.$emit('translated', false);
        }
      }, deep: true
    },
    showAllLanguages: function() {
      this.showAll = this.showAllLanguages;
    },
  },
};
</script>

<style scoped></style>