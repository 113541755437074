<template>
    <div>
        <div class="overline primary--text">All props</div>

        <v-row>
            <v-col class="pl-2 pt-2" cols="12" sm="12" md="5" lg="4" xl="3">
              <Categories
                :auth="auth" :user="user" :settings="settings"
                :edit="false"
                @clickedCategory="categoriesFilter === $event ? categoriesFilter = '' : categoriesFilter = $event"
              ></Categories>
            </v-col>
            <v-col class="pa-0 pt-1 pb-12" cols="12" sm="12" md="7" lg="8" xl="9">
              <div class="pa-4 pt-0">
                <hr class="op-25 mb-4">
                <v-btn small @click="showDetails = !showDetails" class="mr-4">Show Details</v-btn>
                <v-btn small v-if="user.role !== 'user'" @click="editable = !editable">Edit props</v-btn>
              </div>
              <Props
                :auth="auth" :user="user" :settings="settings"
                :edit="editable"
                listView
                :width="[]"
                path="props"
                :details="showDetails"
                :categoriesFilter="categoriesFilter"
                :key="categoriesFilter"
                :noPropsHint="categoriesFilter.length ? `${$t('No props in this category :(')}` : `${$t('No props exist :(')}`"
                :limitReachedHint="$t('There may be more elements to this.')"
                :limit="0"
                highlightSelected
                @clicked="$router.push(`/collection/p/${$event.id}`)"
                @editProp="$router.push(`/newProp/props/${$event.id}`)"
              ></Props>
            </v-col>
        </v-row>
        <!-- @delete="" -->
    </div>
</template>

<script>
import Props from "../components/Props.vue"
import Categories from "../components/Categories.vue"
// import BrightnessSwitcher from "./BrightnessSwitcher.vue"

export default {
  name: 'Collection',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    menu: Boolean,
  },
  components: {
    Props,
    Categories,
  },
  data() {
    return {
      categoriesFilter: '',
      editable: false,
      showDetails: false,
    }
  },
  watch: {
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },

  methods: {
  },
};
</script>