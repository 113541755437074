<template>
  <div>
    <v-btn class="background invert" small v-if="$vuetify.theme.dark" @click="themeSwap('light')" icon>
      <v-icon color="primary invert" class="mx-1">mdi-weather-sunny</v-icon>
    </v-btn>
    <v-btn class="background " small v-else @click="themeSwap('dark')" icon>
      <v-icon color="primary invert" class="mx-1">mdi-weather-night</v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
    },
    components: {
    },
    data() {
      return {
      }
    },
    watch: {
    },

    methods: {
      themeSwap(mode) {
        // https://dev.to/nathan_sheryak/crafting-a-dark-mode-switch-with-vuetify-2ea8
        this.$vuetify.theme.dark = mode == 'dark';
        // TODO: if logged in, write to user
        if(this.auth) {
          this.updateUserField('darkMode', this.user.uid, this.$vuetify.theme.dark)
        }
        this.$helpers.writeLocalStorage("darkMode", this.$vuetify.theme.dark);
      },
      updateUserField(fieldName, userId, value) {
        this.$store.dispatch('updateField', {'collection':'users', 'document':userId,'field':fieldName, 'data': value}).then(() => {
        }).catch(error => {
          console.log(error);
          console.error(error.message);
          this.$toasted.global.error({msg:error.message});
          return;
        });
      },
    },
    created() {
      if(this.auth) {
        // FIMXE: does not load on hard reload
        this.themeSwap(this.user.darkMode ? 'dark' : 'light');
      } else if(this.$helpers.readLocalStorage("darkMode")) {
        this.themeSwap('dark')
      }
    }
  };
</script>
