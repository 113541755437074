<template>
  <div>
    <!-- <pre>propsLocal: {{ propsLocal }}</pre> -->
    <v-card-text v-if="propsLocal.length">
      <div v-if="categoriesFilterLocal.length" class="overline primary--text mb-4">
        {{ $helpers.plural(propsLocal.length, [$t(`Result for category ${categoriesFilterLocal}`), $t(`Results for category ${categoriesFilterLocal}`)]) }}
      </div>

      <div v-for="item in propsLocal" :key="item.id">
        <!-- class wiggle sucks on load -->
        <div three-line @click="select = item.id; $emit('clicked', item)" class="px-4 mb-6 rounded pointer"
          :class="highlightSelected && select === item.id ? 'primary' : item.deleted ? 'red' : 'grey darken-4'"
        >
            <v-row>
              <v-col v-if="item.images.length" class="pa-0" cols="12" sm="2" md="2" lg="2" xl="2">
                <v-img
                  :src="$helpers.getImageVariant(mainImage(item.images), 'thumb')"
                  :lazy-src="$helpers.getImageVariant(mainImage(item.images), 'icon')"
                  cover
                  class="rounded"
                  :width="'100%'"
                  :aspect-ratio="$vuetify.breakpoint.xs ? 16/9 : 1/1"
                ></v-img>
              </v-col>
              <v-col>
                <span v-if="item.name[$i18n.locale]" class="text-subtitle-2">
                  {{ item.name[$i18n.locale] }}
                </span>
                <span v-else class="text-subtitle-2 italics">
                  {{ $t('Unnamed Prop') }} #{{ item.id }}
                </span>
                <span class="caption ml-4 secondary--text" v-if="edit && item.aiDescription.en.length">
                  <v-icon color="secondary" class="pop-in-fancy">mdi-robot</v-icon>
                  {{$t('AI text available for the human touch')}}
                </span>

                <div v-if="user.role !== 'user'">
                  {{$t('Description')}}: {{ item.description[$i18n.locale] ? $helpers.truncate(item.description[$i18n.locale], 50) : $t('No description available') }}
                  <v-icon small class="op-25">mdi-chevron-right</v-icon>
                  {{$t('Article Nr.')}}: <v-chip small class="code">{{ item.id }}</v-chip>
                </div>
                <div>
                  {{$t('Created')}}: {{ $moment($helpers.fbTimeToString(item.createdBy.on)).format("dddd, DD. MM. YYYY - HH:mm:ss") }}
                  <v-icon small class="op-25">mdi-chevron-right</v-icon>
                  {{$t('Edited')}}: {{ item.editedBy.on ? $helpers.timeRelativeToNow($moment($helpers.fbTimeToString(item.createdBy.on))) : $t('Never') }}
                  <v-icon small class="op-25">mdi-chevron-right</v-icon>
                  @ {{ item.createdBy.name }}
                </div>

                <div class="caption ml-4 my-4 secondary--text" v-if="details && item.aiDescription.en.length">
                  <v-icon color="secondary" small>mdi-robot</v-icon>
                  {{ item.aiDescription[$i18n.locale] }}
                </div>
              </v-col>

              <v-col cols="12" v-if="edit" :class="$vuetify.breakpoint.mdAndUp ? 'pl-0' : ''" sm="12" md="1" lg="1" xl="1">
                <v-row>
                  <v-col cols="6" sm="6" md="12" lg="12" xl="12" class="">
                    <v-btn v-if="$vuetify.breakpoint.mdAndUp" block small :class="highlightSelected && select === item.id ? 'white' : 'primary'" @click="select = item.id; $emit('editProp', item); $event.stopPropagation()">
                      <v-icon :color="highlightSelected && select === item.id ? 'primary' : 'white'">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn v-else block small class="secondary" @click="$emit('mobileEdit', {item: item, step: 2}); $event.stopPropagation()">
                      <v-icon color="white">mdi-camera-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" sm="6" md="12" lg="12" xl="12" class="">
                    <v-btn v-if="$vuetify.breakpoint.mdAndUp && !item.deleted" class="red" block small @click="$emit('deleteProp', {item: item, force: false}); $event.stopPropagation()">
                      <v-icon color="white">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn v-else-if="$vuetify.breakpoint.mdAndUp && item.deleted" class="green" block small @click="$emit('deleteProp', {item: item, force: false}); $event.stopPropagation()">
                      <v-icon color="white">mdi-delete-restore</v-icon>
                    </v-btn>
                    <v-btn v-else block small class="green" @click="$emit('mobileEdit', {item: item, step: 4}); $event.stopPropagation()">
                      <v-icon color="white">mdi-ruler</v-icon>
                    </v-btn>
                    <v-btn v-if="item.deleted && user.role === 'admin'" class="orange sine20 mt-6" block small @click="$emit('deleteProp', {item: item, force: true}); $event.stopPropagation()">
                      <v-icon color="white">mdi-delete-forever</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-text v-else-if="noPropsHint.length">
      {{ noPropsHint }} 
    </v-card-text>
    <v-card-text v-if="limit > 0">
      {{ limitReachedHint ? limitReachedHint : $t('There are more elements to this.') }} 
    </v-card-text>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { db } from "../firebase";
/* import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue" */

/* 
EMITS:
@clicked="$event" -> click list item emits {path: path, itemId: item.id} (sic!)
@editProp="$event" -> click edit button emits object {path: path, item: item}

if selected changes value (doc id) && highlightSelected is true, it automatically highlights the selected
*/

export default {
  name: 'Props',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,

    details: Boolean,  // Show ai descr

    categoriesFilter: String,  // ID of category to display

    props: Array,  // if documents already loaded in parent
    edit: Boolean,  // displays delete and edit button
    
    listView: Boolean,  // true = list view or false = gallery
    width: Array,  // defines column wrapping of grid

    path: String,  // can be "propsTemp" or "props" or empty for "props"
    noPropsHint: String,  // is shown when props are 0 - deliver like so :noPropsHint="$t('translated')"

    limit: Number,  // limit amount of props to load from firebase
    highlightSelected: Boolean,  // maybe highlight the one?
    selected: String,  // maybe highlight a specific one

    sortBy: Array,  // Array of objects to srto the query. Default: [{"editedBy.on", "desc"}, {"createdBy.on", "desc"}]
    sortable: Boolean,  // display numerous radio boxes for sorting after name, created, edited, etc

    limitReachedHint: String,  // displayed if limit is not 0

    /* classNames: String,
    keyName: String,
     */
  },
  components: {
  },
  data() {
    return {
      select: '',
      propsLocal: [],
      unsubscribeProps: [],
      categoriesFilterLocal: '',
    }
  },
  mounted() {
  },

  computed: {
  },

  async created() {
    this.limitLocal = this.limit > 0 ? this.limit : 666;
    this.categoriesFilterLocal = this.categoriesFilter ? this.categoriesFilter : '';
    // if no "props" are delivered, get all.limit(limit) documents from "path" collection
    if(this.props) {
      this.propsLocal = this.props;
    } else if(this.path) {
      await this.getProps();
    } else {
      console.log("neither props or path is defined.")
    }
  },
  
  beforeDestroy() {
    if (typeof this.unsubscribeProps === "function") this.unsubscribeProps();
  },

  methods: {
    getProps() {
      // Terminate earlier snapshots if existing
      if (typeof this.unsubscribeProps === "function") {
        // this.unsubscribeProps();
        console.log("did not reload")
        return;
      }
      console.log("did reload")
      // if !admin: .where(deleted === false)  // dont load deleted when regular user
      this.unsubscribeProps = db
        .collection(this.path)
        .orderBy("createdBy.on", "desc");
        // .orderBy("editedBy.on", "desc");

      // Conditionally add the .where clause
      if (this.user.role === 'user') {
        this.unsubscribeProps = this.unsubscribeProps.where("deleted", "==", false);
      }

      // Conditionally add the .categories clause
      if (this.categoriesFilterLocal.length) {
        /* this.unsubscribeProps = this.unsubscribeProps.where("categories", including this.categoriesFilterLocal); */
        this.unsubscribeProps = this.unsubscribeProps.where("categories", "array-contains", this.categoriesFilterLocal)
      }

      this.unsubscribeProps = this.unsubscribeProps.limit(this.limitLocal).onSnapshot(
        (querySnapshot) => {
          let newData = [];
          querySnapshot.forEach((doc) => {
            let f = doc.data();
            f.id = doc.id;
            newData.push(f);
          });
          this.propsLocal = newData;
        }
      );
    },
    
    mainImage(images) {
      let firstImage = images.find((obj) => obj.order === images.length-1);
      return firstImage ? firstImage.url : '';
    },
  },

  watch: {
    selected: function() {
      this.select = this.selected;
    },
    categoriesFilter: function() {
      this.categoriesFilterLocal = this.categoriesFilter;
    }
  },
};
</script>

<style scoped>
</style>