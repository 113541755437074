<template>
  <!-- IMAGE PREVIEW -->
  <div v-if="localImages && localImages.length" :class="containerClasses">
    <v-hover
      v-for="(img, x) in localImages"
      :key="x"
      v-slot="{ hover }"
    >
      <v-img
        class="grey darken-4 inline-block pa-0 pr-3 pl-1 mb-2"
        :class="hover || $vuetify.breakpoint.smAndDown ? 'grey darken-4' : ''"
        elevation-8
        style="vertical-align:middle; transition: background-color .4s ease-in-out;"
        :src="$helpers.getImageVariant(img.url, 'thumb')"
        :lazy-src="$helpers.getImageVariant(img.url, 'icon')"
        :contain="!contain && !cover ? true : hover ? false : contain"
        :cover="hover || cover"
        :width="`${width ? width : 50}%`"
        :height="`${width ? width : 50}vw`"
        @click="magnify(img, x)"
      >
        <!-- @error="waitForThumbnails(localImages, x)" -->
        <v-btn
          v-if="hover && edit && (user.role === 'admin' || user.role === 'supervisor')"
          :style="hover || $vuetify.breakpoint.smAndDown ? 'opacity: 1' : ''"
          small color="red" icon absolute top right class=""
          style="opacity: 0; transition: opacity .4s ease-in-out;"
          @click="removeImg(x); $event.stopPropagation();"
        ><v-icon color="red" class="text-shadow--black">mdi-delete</v-icon>
        </v-btn>

        <div
          v-if="hover && localImages.length > 1 && edit && (user.role === 'admin' || user.role === 'supervisor')"
          class="absolute bottom left fill-width"
          :class="x === 0 ? 'text-right pr-4' : x === localImages.length-1 ? 'text-left pl-4' : 'text-center'"
          style="opacity: 0; transition: opacity .4s ease-in-out;"
          :style="hover || $vuetify.breakpoint.smAndDown ? 'opacity: 1' : ''"
          >
          <v-btn
            v-if="x > 1"
            icon
            class="mr-0 pa-0"
            @click="shiftImg(x, 0); $event.preventDefault()"
          ><v-icon dense small color="white" class="text-shadow--black">mdi-arrow-collapse-left</v-icon>
          </v-btn>
          <span v-else class="px-4"></span>
          <!-- :class="x != localImages.length-1 ? 'nudge-x--400' : ''" -->
          <v-btn
            v-if="x != 0"
            icon
            class="mr-0 pa-0"
            @click="shiftImg(x, x-1); $event.preventDefault()"
          ><v-icon small color="white" class="text-shadow--black">mdi-arrow-left</v-icon>
          </v-btn>

          <!-- "mdi-arrow-left", "mdi-arrow-right", "mdi-arrow-collapse-right",  -->

          <span v-if="x != 0 && x != localImages.length-1" class="px-4"></span>
          <span v-if="x == 0" class="px-1 text-shadow--black">{{ $t('Main') }}</span>

          <v-btn
            v-if="x != localImages.length-1"
            icon
            class="mr-0 pa-0"
            @click="shiftImg(x, x+1); $event.preventDefault()"
          ><v-icon small color="white" class="text-shadow--black">mdi-arrow-right</v-icon>
          </v-btn>

          <v-btn
            v-if="x < localImages.length-2"
            icon
            class="mr-0 pa-0"
            @click="shiftImg(x, localImages.length-1); $event.preventDefault()"
          ><v-icon small color="white" class="text-shadow--black">mdi-arrow-collapse-right</v-icon>
          </v-btn>
          <span v-else class="px-4"></span>
        </div>
      </v-img>
    </v-hover>

    <!-- MAGNIFY  -->
    <v-dialog
      v-model="magnifyDialog"
      :max-width="$vuetify.breakpoint.sm
        ? '85%'
        : magnifyDialogImg.image && magnifyDialogImg.image.width > magnifyDialogImg.image.height ? '85%': '45%'
      "
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :ripple="false"
      scrollable
    >
      <v-card :ripple="false">
        <v-card-text class="pa-0" style="max-height:90%;" :class="$vuetify.breakpoint.smAndDown ? 'relative' : ''">
          <v-img
            class="pointerClose grey darken-3 fill-height"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'relative'"
            :src="$helpers.getImageVariant(magnifyDialogImg.url)"
            :lazy-src="$helpers.getImageVariant(magnifyDialogImg.url, 'large')"
            @click="magnifyDialog = false"
            contain
            >
            <!-- @error="waitForThumbnails([magnifyDialog], 0)" -->
            <div v-if="localImages.length > 1"
              v-touch="{
                left: () => magnifyPrev(),
                right: () => magnifyNext(),
              }"
              @click.stop="magnifyPrev()"
              class="pointerLeft absolute left fill-height"
              :style="$vuetify.breakpoint.xs ? 'width:50%' : 'width:33%'"
            >
            </div>
            <div v-if="localImages.length > 1"
              v-touch="{
                left: () => magnifyPrev(),
                right: () => magnifyNext(),
              }"
              @click.stop="magnifyNext()"
              class="pointerRight absolute right fill-height"
              :style="$vuetify.breakpoint.smAndDown ? 'width:50%' : 'width:33%'"
            >
              <div v-if="$vuetify.breakpoint.smAndDown" class="text-right fixed top right ma-4">
                <v-btn small color="red" fab absolute top right class="mt-8" @click="magnifyDialog = false" >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </v-img>
        </v-card-text>
      <v-card-actions class="px-2 py-1 caption grey--text">
        {{$helpers.truncate(magnifyDialogImg.name, 25)}}
        <v-spacer></v-spacer>
        {{ magnifyIndex+1 }}/{{ localImages.length }} -
        {{ $moment(magnifyDialogImg.lastModified).format("DD.MM.YYYY") }}
        <v-spacer></v-spacer>
        <Share
          position="bottom"
          buttonColor="white"
          :text="magnifyDialogImg.url"
          tooltip="Share this image"
        ></Share>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="magnifyDialog = false" class="mx-2 mt-4" color="grey">
          <v-icon class="mr-2">mdi-close</v-icon>
          {{ $t("Close") }}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Share from '@/components/Share'

  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      images: Array,
      edit: Boolean,
      width: Number,
      containerClasses: String,
      contain: Boolean,
      cover: Boolean,
    },
    components: {
      Share
    },
    data() {
      return {
        localImages: [],
        magnifyIndex: 0,
        magnifyDialog: false,
        magnifyDialogImg: {},
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      images: function(oldsearch, newsearch) {
        this.localImages = this.images;
      },
    },
    created() {
      this.localImages = this.images;
    },
    methods: {
      magnify(imgData, index) {
        this.magnifyDialog = true;
        this.magnifyIndex = index;
        this.magnifyDialogImg = imgData;
      },
      magnifyPrev() {
        this.magnifyIndex = this.magnifyIndex-1;
        this.magnifyIndex = this.magnifyIndex < 0
          ? this.localImages.length-1
          : this.magnifyIndex;
        this.magnifyDialogImg = this.localImages[this.magnifyIndex];
      },

      magnifyNext() {
        this.magnifyIndex = this.magnifyIndex+1;
        this.magnifyIndex = this.magnifyIndex > this.localImages.length-1
          ? 0
          : this.magnifyIndex;
        this.magnifyDialogImg = this.localImages[this.magnifyIndex];
      },
      /* localImages */
      removeImg(index) {
        // Delete from storage, including _large, _thumb, _icon!
        let path = this.localImages[index].path;
        this.$store.dispatch('deleteFile', path);
        // Tries to delete any thumbnails. If it fails (eg. for .svgs which don't have thumbnails), no matter.
        this.$store.dispatch('deleteFile', this.$helpers.getImageVariant(path, 'large'));
        this.$store.dispatch('deleteFile', this.$helpers.getImageVariant(path, 'thumb'));
        this.$store.dispatch('deleteFile', this.$helpers.getImageVariant(path, 'icon'));

        // Remove array element
        this.localImages.splice(index, 1);
        this.setNewImageOrder();
      },
      shiftImg(oldIndex, newIndex) {
        // console.log(oldIndex, newIndex);
        this.moveArray(this.localImages, oldIndex, newIndex)
      },
      moveArray(array, oldIndex, newIndex) {
        if (newIndex >= array.length) {
            newIndex = array.length - 1;
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
        // write to imgs[{.order: newIndex}]
        this.setNewImageOrder();
        return array;
      },
      setNewImageOrder() {
        // Save new order key to resource data
        for (let i = 0; i < this.localImages.length; i++) {
          // console.log(this.localImages.length-i-1, this.localImages[i].name, this.localImages[i].order)
          this.localImages[i].order = this.localImages.length-i-1;
        }
      },
    },
  };
</script>
