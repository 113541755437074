<template>
  <div :class="classNames">
    <v-icon small class="mb-1 grey--text">mdi-home</v-icon>
    <v-icon small class="mx-4 mb-1">mdi-chevron-right</v-icon>
    <v-breadcrumbs :items="crumbs" class="pa-0 inline">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item>
          <!-- <v-icon v-if="crumbs.indexOf(item) === 0">mdi-home</v-icon> -->
          <span :class="crumbs.indexOf(item) === crumbs.length-1 ? 'italics secondary--text' : ''">
            {{ crumbNames[item] ? crumbNames[item][$i18n.locale] : $t('New category')+".." }}
          </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      crumbNames: Object,
      crumbs: Array,
      classNames: String,
    },
    components: {
    },
    data() {
      return {
        localCrumbs: [],
      }
    },
    watch: {
      crumbs: function() {
        this.localCrumbs = this.crumbs;
      }
    },
    methods: {
      /* crumbos(item) {
        let name = this.$helpers.findKeyRecursive(this.crumbNames, 'id', item, 'name')
        if(name) {
          name = name[this.$i18n.locale];
        } else {
          name = this.$t('New category')+"..";
        }
        return name
      }, */
    },
    computed: {
    },
    created() {
      /* if(!this.crumbs.length) {
        this.localCrumbs = ['home', `xxx`];
      } else {
        this.crumbs = ['home', ...this.crumbs]
      } */
    }
  };
</script>
