<template>
  <div>
    <span v-for="(tag, index) in tags" :key="index" class="mb-4">
      <div
        v-if="tags[index - 1] ? tags[index - 1].category !== tag.category : true"
        class="grey--text pt-4 mb-2"
      >
        <v-icon small>mdi-tag-multiple</v-icon>
        {{ $t(tag.category) }}
      </div>
      <v-chip class="mr-2 mb-2 white--text text-shadow--black" :color="tag.color" small text-color="white" @click="edit ? setupEditTag(tag) : $emit('addTag', tag)">
        {{ tag.name[$i18n.locale] }}
        <!-- <v-icon small @click="newTag = tag; editTag = true">mdi-edit</v-icon> -->
      </v-chip>
    </span>

    <div class="overline primary--text">
      <v-icon small left>mdi-tag-plus</v-icon>
      {{ $t("New Tag") }}
    </div>

    <TagCategorySelect :categories="categories" :selected="newTag.category" @select="newTag.category = $event"></TagCategorySelect>

    <TextfieldAutotranslate
      :auth="auth" :user="user" :settings="settings"
      :triggerTranslations="triggerTranslations"
      @translated="translationFinished['tagName'] = !!$event"
      :data="newTag.name" 
      classNames=""
      classMainContainer=""
      initialRowHeight="1"
      :dense="true"
      :oneLine="true"
      prefix="item"
      :placeholder="$t('Name of new tag')"
    ></TextfieldAutotranslate>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary mt-4"
          :style="`background-color: ${newTag.color} !important`"
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-icon class="white--text">mdi-palette</v-icon>
        </v-btn>
      </template>

      <v-color-picker
        v-model="newTag.color"
        dot-size="17"
        hide-inputs
        show-swatches
        :swatches="swatches"
        hide-mode-switch
        mode="hexa"
      ></v-color-picker>
    </v-menu>

    <a
      v-if="editTag && user.role === 'admin'"
      target="_blank"
      class="mx-2 no-underline"
      :title="`Open Firebase document of this tag`"
      :href="`https://console.firebase.google.com/project/dbcatourne/firestore/data/~2Ftags~2F${newTag.id}`"
    >
      <v-icon class="orange--text">mdi-firebase</v-icon>
    </a>

    <v-checkbox
      :disabled="loading"
      v-model="waitForLanguageConfirmation"
      :label="$t(`Wait for translation confirmation`)"
    ></v-checkbox>

    <v-btn v-if="editTag" @click="clearNewTag(); editTag = false" class="mx-2 mt-4" color="grey">
      <v-icon class="mr-2">mdi-close</v-icon>
      {{ $t("Cancel") }}
    </v-btn>

    <v-btn v-if="editTag" @click="deleteTag(newTag.id); editTag = false" class="mx-2 mt-4" color="error">
      <v-icon class="mr-2">mdi-delete</v-icon>
      {{ $t("Delete") }}
    </v-btn>

    <v-btn v-if="!(loading && waitForLanguageConfirmation)" @click="saveTag()" class="mx-2 mt-4" color="primary" :disabled="disabledSaveBtn || loading" :loading="loading">
      <v-icon class="mr-2">mdi-content-save</v-icon>
      {{ loading? `${$t('Loading')}..` : editTag ? $t("Edit") : $t("Save new") }}
    </v-btn>

    <v-btn v-if="loading && waitForLanguageConfirmation" @click="waitForLanguageConfirmation = false; confirmTranslation = false" class="mx-2 mt-4" color="grey">
      <v-icon class="mr-2">mdi-content-save</v-icon>
      {{ $t("Cancel") }}
    </v-btn>

    <v-btn v-if="loading && waitForLanguageConfirmation" @click="confirmTranslation = true" class="mx-2 mt-4" color="green">
      <v-icon class="mr-2">mdi-content-save</v-icon>
      {{ $t("Confirm translation?") }}
    </v-btn>

  </div>
</template>

<script>
import { db } from "../firebase";
import TextfieldAutotranslate from "../components/TextfieldAutotranslate.vue"
import TagCategorySelect from "../components/TagCategorySelect.vue"

export default {
  props: {
    auth: Boolean,
    edit: Boolean,
    user: Object,
    settings: Object,
  },
  components: {
    TextfieldAutotranslate,
    TagCategorySelect
  },
  data() {
    return {
      tags: [],
      unsubscribeTags: [],
      unsubscribeTagCategories: [],
      categories: {},
      translationFinished: {
        'tagName': false
      },
      loading: false,
      waitForLanguageConfirmation: false,
      confirmTranslation: false,
      triggerTranslations: '',
      newTag: { category: "10"},  // ACHTUNG. If this site does not load, its because category nr. 10 does not exist anymore.
      editTag: false,
      swatches: [
        // Column 1
        ['#FFB6C1', '#FFC0CB', '#FF69B4', '#FF1493', '#DB7093', '#C71585', '#FFA07A', '#FA8072', '#E9967A', '#F08080',
        '#CD5C5C', '#DC143C', '#B22222', '#FF0000', '#8B0000', '#FFA500', '#FF8C00', '#FF7F50', '#FF6347', '#FF4500',
        '#FFD700', '#FFFF00', '#FFFFE0', '#FFFACD', '#FAFAD2', '#FFEFD5', '#FFE4B5', '#FFDAB9', '#EEE8AA', '#F0E68C'],
        // Column 2
        ['#BDB76B', '#FFFAF0', '#FDF5E6', '#FFE4E1', '#FFDEAD', '#F5DEB3', '#DEB887', '#D2B48C', '#BC8F8F', '#F4A460',
        '#DAA520', '#B8860B', '#CD853F', '#D2691E', '#8B4513', '#A0522D', '#A52A2A', '#800000', '#556B2F', '#808000',
        '#6B8E23', '#9ACD32', '#32CD32', '#00FF00', '#7FFF00', '#7CFC00', '#ADFF2F', '#00FF7F', '#00FA9A', '#90EE90'],
        // Column 3
        ['#98FB98', '#8FBC8F', '#66CDAA', '#3CB371', '#2E8B57', '#228B22', '#008000', '#006400', '#9ACD32', '#6B8E23',
        '#556B2F', '#66CDAA', '#8FBC8B', '#20B2AA', '#008B8B', '#008080', '#48D1CC', '#00CED1', '#40E0D0', '#00FFFF',
        '#E0FFFF', '#AFEEEE', '#7FFFD4', '#B0E0E6', '#5F9EA0', '#4682B4', '#6495ED', '#00BFFF', '#1E90FF', '#ADD8E6'],
        // Column 4
        ['#B0C4DE', '#87CEEB', '#87CEFA', '#00BFFF', '#1E90FF', '#6495ED', '#7B68EE', '#4169E1', '#0000FF', '#0000CD',
        '#00008B', '#000080', '#191970', '#6A5ACD', '#483D8B', '#7B68EE', '#9370DB', '#8A2BE2', '#9400D3', '#9932CC',
        '#BA55D3', '#D8BFD8', '#DDA0DD', '#EE82EE', '#FF00FF', '#DA70D6', '#C71585', '#DB7093', '#FF1493', '#FF69B4'],
        // Column 5
        ['#FFB6C1', '#FFC0CB', '#DC143C', '#FF6347', '#FF4500', '#FF8C00', '#FFA500', '#FFD700', '#FFFF00', '#FFFFE0',
        '#FFFACD', '#FAFAD2', '#FFEFD5', '#FFE4B5', '#FFDAB9', '#EEE8AA', '#F0E68C', '#BDB76B', '#FFFAF0', '#FDF5E6',
        '#FFE4E1', '#FFDEAD', '#F5DEB3', '#DEB887', '#D2B48C', '#BC8F8F', '#F4A460', '#DAA520', '#B8860B', '#CD853F']
      ],
    };
  },
  created() {
    // Get Tags
    this.setupCategories();
    this.getTags();
    this.clearNewTag();
  },
  beforeDestroy() {
    if (typeof this.unsubscribeTags === "function") this.unsubscribeTags();
    if (typeof this.unsubscribeTagCategories === "function") this.unsubscribeTagCategories();
  },

  computed: {
    disabledSaveBtn() {
      let nameIsNotEmpty = !Object.values(this.newTag.name).some(value => value !== "")
      return this.newTag.color.length == 0 || this.newTag.category.length == 0 || nameIsNotEmpty;
    }
  },

  methods: {
    clearNewTag() {
      // FIXME: does not clear after saving????
      this.newTag = {
        category: this.newTag.category,
        color: this.$helpers.randomColor('hex')+"FF",
        name: this.$i18n.availableLocales.reduce((acc,curr)=> (acc[curr]='',acc),{})
      }
    },

    setupCategories() {
      if (typeof this.unsubscribeTagCategories === "function") {
        this.unsubscribeTagCategories();
      }
      this.unsubscribeTagCategories = db.collection("settings")
      .doc("tagCategories")
      .onSnapshot(doc => {
        let data = doc.data();
        this.categories = data.categories;
      });
    },

    getTags() {
      // Terminate earlier snapshots if existing
      if (typeof this.unsubscribeTags === "function") {
        this.unsubscribeTags();
      }
      this.unsubscribeTags = db
        .collection("tags")
        .orderBy("category", "asc")
        .orderBy("name", "asc");

      this.unsubscribeTags = this.unsubscribeTags.onSnapshot(
        (querySnapshot) => {
          let newData = [];
          querySnapshot.forEach((doc) => {
            let f = doc.data();
            f.id = doc.id;
            // Populate category names
            // Do not show category 0 / "Testing" to other users than admin
            if(f.category > "0" || (f.category === "0" && this.user.role === 'admin')) {
              f.category = this.categories[f.category];
              newData.push(f);
            }
          });
          this.tags = newData;
        }
      );
    },

    setupEditTag(data) {
      this.newTag = JSON.parse(JSON.stringify(data));
      //  reverse the population
      this.newTag.category = Object.keys(this.categories).find(key => this.categories[key] === this.newTag.category);
      this.editTag = true;
    },

    async saveTag() {
      let data = this.newTag;  // Get the data from the translation!
      this.loading = true;

      if(!Object.values(this.translationFinished).every(value => value === true)) {
        // trigger translation if some text is not yet translated
        this.triggerTranslations = this.$helpers.createUid();
        while(!Object.values(this.translationFinished).every(value => value === true)) {
          console.log("wait for translation..");
          await this.$helpers.sleep(50);
        }
      }

      if(this.waitForLanguageConfirmation) {
        while(!this.confirmTranslation && this.waitForLanguageConfirmation) {
          console.log("wait for user to press the button");
          await this.$helpers.sleep(100);
        }
        if(!this.waitForLanguageConfirmation) {
          // User pressed cancel
          console.log("User pressed cancel");
          this.loading = false;
          this.waitForLanguageConfirmation = true;
          Object.keys(this.translationFinished).forEach(key => this.translationFinished[key] = false);
          return;
        }
      }
      // await this.translateTag("", "");  // If any text is untranslated, translate now
      if(this.editTag) {
        let id = data.id;
        delete data.id;
        await db.collection("tags").doc(id).update(data).then(async function() {
          return true
        }).catch(function(error) {
          throw error;
        });
      } else {
        await db.collection("tags").doc().set(data).then(async function() {
          return true;
        }).catch(function(error) {
          console.log("error in tags/saveTag!", error);
          throw error;
        });
      }

      this.editTag = false;
      this.confirmTranslation = false;
      this.loading = false;
      Object.keys(this.translationFinished).forEach(key => this.translationFinished[key] = false);
      this.clearNewTag();
      this.$toasted.global.success({msg:this.$t(`New tag created!`)});
    },

    async deleteTag(id) {
      await db.collection("tags").doc(id).delete().then(function() {
        return true
      }).catch(function(error) {
        throw error;
      });
      this.$toasted.global.success({msg:this.$t(`Deleted Tag`)});
      this.clearNewTag();
      this.editTag = false;
    }
  },
  mounted() {},
};
</script>
