<template>
    <div v-if="user.role === 'supervisor' || user.role === 'admin'">
        <div class="overline primary--text">Finances</div>

        <Camera :auth="auth" :user="user" :settings="settings"></Camera>
    </div>
</template>

<script>
import Camera from "../components/Camera.vue"

export default {
  name: 'Finances',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object
  },
  components: {
    Camera
  },
  data() {
    return {
      on: false,
      attrs: false,
    }
  },
  watch: {
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },

  methods: {
  },
};
</script>