<template>
    <v-app-bar
      app
      clipped-left
    >

      <img v-if="$vuetify.breakpoint.mdAndUp" class="op-50" :class="$vuetify.theme.dark ? 'invert' : 'darken-3'" src="logo.svg" style="height:1.5em">
      <img v-if="$vuetify.breakpoint.mdAndUp" class="ml-4" :class="$vuetify.theme.dark ? 'invert' : 'darken-3'" src="stellarDb.svg" style="height:1.5em"
        title="Stellar [/ˈstɛlə/]: 1. Of, pertaining to, or characteristic of stars. 2. Heavenly 3. (by extension) Exceptional, exceptionally good."
      >

      <!-- MOBILE HAMBURGER -->
      <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="$emit('menu');" small>
          <v-icon color="">mdi-menu</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <!-- SPECIAL WARNING BUTTONS -->
      <v-btn v-if="auth && !user.emailVerified" small class="red mx-1" :style="$vuetify.breakpoint.mdAndUp ? 'width:161px;' : ''" to="/profile?hint=verifyEmail" type="submit" color="">
          <span v-if="$vuetify.breakpoint.mdAndUp">Please verify email</span>
          <v-icon v-else>mdi-email-remove</v-icon>
      </v-btn>
      <div v-else-if="$vuetify.breakpoint.mdAndUp" style="width:161px;"></div>

      <v-btn v-if="auth && settings && settings.maintenance && user.role == 'admin'" small to="/admin" class="mx-1 error">
          <v-icon :small="$vuetify.breakpoint.mdAndUp" color="yellow">mdi-warning</v-icon>
          Maintenance mode
      </v-btn>

      <!-- <v-btn v-if="!auth && $route.path != '/login'" text icon small :circle="$vuetify.breakpoint.mdAndUp" to="/login" class="mx-1" v-bind="attrs" v-on="on">
          <v-icon :small="$vuetify.breakpoint.mdAndUp" color="primary">mdi-login-variant</v-icon>
      </v-btn> -->

      <LanguageSwitcher :auth="auth" :user="user" :settings="settings"></LanguageSwitcher>
      <BrightnessSwitcher :auth="auth" :user="user" :settings="settings"></BrightnessSwitcher>

      <!-- Breakpoint helpers -->
      <!-- https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service -->
      <!-- <div class="fixed top left pa-3 text-h4" style="background-color:rgba(0,0,0,0.75)">
          <span class="px-1" :class="$vuetify.breakpoint.xs ? 'green--text' : 'grey--text text--darken-2'">{{$vuetify.breakpoint.xs ? 'XS' : 'XS'}}</span>
          <span class="px-1" :class="$vuetify.breakpoint.sm ? 'green--text' : 'grey--text text--darken-2'">{{$vuetify.breakpoint.sm ? 'SM' : 'SM'}}</span>
          <span class="px-1" :class="$vuetify.breakpoint.md ? 'green--text' : 'grey--text text--darken-2'">{{$vuetify.breakpoint.md ? 'MD' : 'MD'}}</span>
          <span class="px-1" :class="$vuetify.breakpoint.lg ? 'green--text' : 'grey--text text--darken-2'">{{$vuetify.breakpoint.lg ? 'LG' : 'LG'}}</span>
          <span class="px-1" :class="$vuetify.breakpoint.xl ? 'green--text' : 'grey--text text--darken-2'">{{$vuetify.breakpoint.xl ? 'XL' : 'XL'}}</span>
      </div> -->
    </v-app-bar>
  </template>
  
  <script>
  import LanguageSwitcher from "./LanguageSwitcher.vue"
  import BrightnessSwitcher from "./BrightnessSwitcher.vue"
  
  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
    },
    components: {
      LanguageSwitcher,
      BrightnessSwitcher
    },
    data() {
      return {
      }
    },
    watch: {
    },
  
    methods: {
    },
  };
  </script>