<template>
    <div v-if="user.role === 'supervisor' || user.role === 'admin'">
        <div class="overline primary--text">MngTags</div>
        <Tags :edit="true" :auth="auth" :user="user" :settings="settings"></Tags>
        <!-- <Tags @addTag="bliblah = $event" :edit="true" :auth="auth" :user="user" :settings="settings"></Tags> -->
    </div>
</template>

<script>
/* import LanguageSwitcher from "./LanguageSwitcher.vue" */
import Tags from "../components/Tags.vue"

export default {
  name: 'MngTags',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object
  },
  components: {
    Tags
  },
  data() {
    return {
      on: false,
      attrs: false,
    }
  },
  watch: {
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },

  methods: {
  },
};
</script>