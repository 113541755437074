<template>
    <div>
        <div class="overline primary--text">Productions</div>
    </div>
</template>

<script>
/* import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue" */

export default {
  name: 'Productions',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object
  },
  components: {
    /* LanguageSwitcher,
    BrightnessSwitcher */
  },
  data() {
    return {
      on: false,
      attrs: false,
    }
  },
  watch: {
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },

  methods: {
  },
};
</script>