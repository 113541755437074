const authKeys = {
    'deepl': {
        auth_key: '131f0200-65ea-0616-678e-01b0126d00b3:fx'
    },
    'fb' : {
        apiKey: "AIzaSyBvxmjOAqjvJi5ut7lijdhqUjt9yJH09vE",
        authDomain: "dbcatourne.firebaseapp.com",
        projectId: "dbcatourne",
        storageBucket: "dbcatourne",  // dbcatourne.appspot.com
        messagingSenderId: "1072672446908",
        appId: "1:1072672446908:web:41c951d5caccf6c78333b9",
        measurementId: "G-5DBJTEHRX2"
    },
    /* 'openai' : {
        'apiKey': "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"
    } */
}

export { authKeys }
