<template>
    <div v-if="user.role === 'supervisor' || user.role === 'admin'">
      <div class="overline primary--text">MngCategories</div>
      <Categories :edit="true" @clickedCategory="test($event)" :auth="auth" :user="user" :settings="settings"></Categories>
  </div>
</template>

<script>
// import { db } from "../firebase";
import Categories from "../components/Categories.vue"

export default {
  props: {
    auth: Boolean,
    edit: Boolean,
    user: Object,
    settings: Object,
  },
  components: {
    Categories
  },
  data() {
    return {
    };
  },
  created() {
  },
  
  beforeDestroy() {
  },

  computed: {
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    test(id) {
      console.log("received clicked ID: ", id);
    }
  },
  mounted() {},
};
</script>
