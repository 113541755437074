<template>
  <v-stepper v-model="step" tile class="fill-height">
    <v-stepper-header class="" color="red">
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        {{$t('Name')}}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        {{$t('Images')}}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step 
        :complete="step > 3"
        step="3"
      >
        {{$t('Category & tags')}}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4">
        L&times;B&times;H, W
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items class="fill-height">
      <v-stepper-content step="1">
        {{$t('Name')}}
        <v-text-field
          tabindex=""
          v-model="propLocal.name[$i18n.locale]"
          :placeholder="$t('Name')"
          filled
        ></v-text-field>

          MOBILE VERSION:<br>
          kinda fullscreen.<br><br>

          first button:<br>
          NEW / edit currently tempProps<br><br>

          If new - do not save in TEMP yet (but maybe trigger AI description of first image somehow?):<br><br>
          1.) Images -> title input -> big button "add more details", big button "SAVE TEMP AND NEW" -> TRIGGER AI DESCRIPTIONS <br><br>
          2.) CATEGORY -> CATEGORY input -> big button "add more details", big button "SAVE TEMP AND NEW" <br><br>
          3.) TAGS -> TAGS input -> big button "add more details", big button "SAVE TEMP AND NEW" <br><br>
          4.) LBHW -> LBHW input -> big button "add more details", big button "SAVE TEMP AND NEW" <br><br>
      </v-stepper-content>

      <v-stepper-content step="2" class="fill-height ">
        <v-container fluid class="fill-height"><!-- this element is not full height.... -->
          <v-row class="fill-height ">
            <v-col class="text-center ">
              {{$t('Images')}}
              <FileUpload
                tabindex=""
                class="inline"
                type="image/*"
                :target="`props/${propLocal.id}`"
                :multiple="true"
                :orderStart="propLocal.images.length"
                icon="image-plus"
                iconClasses=""
                :camera="$vuetify.breakpoint.smAndDown"
                buttonClasses=""
                position="right"
                @uploaded="propLocal.images.unshift($event);"
                @error="$toasted.global.error({msg:$event})"
                >
                <!-- @uploaded="newImagesUploaded($event)" -->
                {{ $helpers.plural(propLocal.images.length, [$t('No images as of yet'), $t('image'), $t('images')]) }}
                <!-- {{ generatingThumbnails ? ` [${$t('Generating thumbnails')}..]` : '' }} -->
              </FileUpload>
              
              <v-btn v-if="propLocal.images.length" @click="editImages = !editImages" small class="ml-2">{{ $t('Edit images') }}..</v-btn>

              <Gallery
                :auth="auth" :user="user" :settings="settings"
                :width="$helpers.breakpointChooser($vuetify.breakpoint, editImages ? [100,33.33,25,20,12.5] : [25,12.5,12.5,8.125,6.25])"
                containerClasses="mt-4"
                contain
                :images="propLocal.images"
                :edit="editImages"
              ></Gallery>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper-content>

      <v-stepper-content step="3">
        {{$t('Category & tags')}}
        {{ propLocal.category }},
        {{ propLocal.tags }}
      </v-stepper-content>

      <v-stepper-content step="4">
        {{$t('L&times;B&times;H, W')}}
        {{ propLocal.dimensions.l }}&times;
        {{ propLocal.dimensions.b }}&times;
        {{ propLocal.dimensions.h }},
        {{ propLocal.dimensions.w }}
      </v-stepper-content>
    </v-stepper-items>


    <v-card-actions class="pa-4 absolute fill-width text-center left bottom">
      <v-btn @click="$emit('close')">
        {{$t('Close')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        :disabled="step === 1"
        @click="step--"
      >
        {{$t('Previous')}}
      </v-btn>
      SAVE TEMP?
      /
      SAVE FINAL?
      <v-btn
        color="primary"
        v-if="step < 4"
        @click="step++"
      >
        {{$t('Next')}}
      </v-btn>
      <v-btn
        color="primary"
        v-else
        @click="$emit('close')"
      >
        {{$t('Save')}}
      </v-btn>
    </v-card-actions>

  </v-stepper>
</template>

<script>
/* import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue" */
import FileUpload from "../components/FileUpload.vue"
import Gallery from "../components/Gallery.vue"

export default {
  name: 'MobilePropEdit',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    selectStep: Number,
    prop: Object,
  },
  components: {
    FileUpload,
    Gallery,
  },
  created() {
    this.propLocal = this.prop;
    this.step = this.selectStep;
  },
  data() {
    return {
      step: 1,
      editImages: false,
    }
  },
  watch: {
    selectStep() {
      this.step = this.selectStep;
    },
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },

  methods: {
  },
};
</script>