<template>
  <div v-if="user.role === 'supervisor' || user.role === 'admin'">
    <!-- USERS -->
    <div class="overline primary--text">User Management</div>

    {{users.length}} users in total, combined effort: <span class="orange--text">{{totalContribution >= 0 ? totalContribution.toLocaleString() : '--'}}</span> contribution points
    <br>
    <small class="grey--text">
      Sort / filter by
      <label class="mx-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('createdOn', 'desc')" checked> Created</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('contribution','desc')"> Contribution</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('public', 'desc')"> Public</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('name','asc')"> Name</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('email','asc')"> Email</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('role','asc')"> Role</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('lastLogin','desc')"> Last login</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('kicked','desc')"> Kicked</label>
      <label class="mr-3 nobr pointer"><input type="radio" class="text--vert-middle invert" name="sortby" @change="getUsers('deletedUser', 'desc')"> Deleted</label>
    </small>

    <div class="pt-4 text-right">
      <v-btn small dense color="primary" class="my-2" :href="`mailto:info@fluescher.ch?bcc=${users.map(x => {if(x.news || x.news === undefined) {return `${x.name}<${x.email}>`}}).filter(x => x).join(',%20')}`">Write email to every willing user</v-btn>
    </div>
    <div class="pa-0 pb-4" style="max-height:1000px; overflow-y:auto;">
      <div
        v-for="(singleUser, i) in users"
        class="my-2 pl-2 py-2 relative rounded"
        :key="i"
        :style="i % 2 == 0 ? 'background-color:rgba(125,125,125,.05)' : ''"
      >
        <v-btn dense small class="ma-2 red right top absolute" :disabled="singleUser.id === user.uid" v-if="singleUser.kicked" @click="updateUserField('kicked', singleUser.id, false)">unkick me</v-btn>
        <v-btn dense small class="ma-2 right top absolute" :disabled="singleUser.id === user.uid" v-else-if="singleUser.kicked === false" @click="updateUserField('kicked', singleUser.id, true)">kick me</v-btn>
        
        <v-btn dense small class="ma-2 hover right top absolute" :class="singleUser.deletedUser ? '' : 'mt-10'" :disabled="singleUser.id === user.uid" v-if="userConfirmDelete !== singleUser.id" @click="userConfirmDelete = singleUser.id">delete {{$helpers.getFirstName(singleUser.name, "this")}}'s account</v-btn>
        <v-btn dense small class="ma-2 right top absolute error--fade" :class="singleUser.deletedUser ? '' : 'mt-10'" v-else @click="userDelete(singleUser.id, singleUser.role)">yes delete me please dear god</v-btn>
        
        <div v-if="singleUser.deletedUser" class="">
          Self deleted user <b class="orange--text">#{{i+1}}</b>
          &middot;
          <div class="inline mr-2">
            <pre class="grey--text inline">{{singleUser.id}}</pre>
            <Copy :data="singleUser.id" opacity=".75" dataName="user ID"></Copy>
          </div>
          &middot;
          <small>
            Edited on:
            {{$helpers.fbTimeToString(singleUser.editedOn, "DD.MM.YY - HH:mm") }}
          </small>
        </div>
        <div v-else class="pr-2 maxWidth" :style="$vuetify.breakpoint.mdAndUp ? 'width:25%;' : 'width:100%;'" style="overflow:hidden; vertical-align: top; display:inline-block;">
          
        <div class="text-h6 overflowHidden ellipsis">
          <v-avatar color="grey" class="inlineBlock">
            <v-img
              v-if="singleUser.avatar.url"
              :src="singleUser.avatar.url"
            ></v-img>
            <v-img
              v-else
              :src="`https://api.dicebear.com/7.x/adventurer-neutral/svg?seed=${singleUser.avatar && singleUser.avatar.random ? singleUser.avatar.random : $helpers.md5(singleUser.uid)}.svg`"
            ></v-img>
            <!-- <span v-else class="text-h5 uppercase">{{singleUser.name[0]}}</span> -->
          </v-avatar>
          <span :title="'User: '+singleUser.name" class="pl-2">{{singleUser.name}} {{ singleUser.email === user.email ? '(you)' : '' }}</span>
        </div>
          
        </div>
        <div v-if="!singleUser.deletedUser" class="grey--text" :style="$vuetify.breakpoint.mdAndUp ? 'width:75%;' : 'width:100%;'" style="display:inline-block">
          <a :href="`mailto:${singleUser.email}`"
            class="no-underline"
            :title="singleUser.emailVerified ? `Email ${singleUser.email} verified` : `Email ${singleUser.email} not verified`"
          >
            <v-icon
              small
              class="mr-1"
              :class="singleUser.emailVerified ? 'green--text' : 'red--text'">
              {{singleUser.emailVerified  ? 'mdi-check-circle' : 'mdi-close-circle'}}
            </v-icon>
            {{singleUser.email}}
          </a>
          <br>
          Role:
          <b class="pointer inline"
            :class="$helpers.findElementInNewArray(singleUser.role, ['user', 'supervisor', 'admin'], ['green--text', 'orange--text', 'pink--text'])"
            @click="setRoleForUser($helpers.findElementInNewArray(singleUser.role, ['user', 'supervisor', 'admin'], ['supervisor', 'admin', 'user']), singleUser.uid)" 
            :title="$helpers.findElementInNewArray(singleUser.role, ['user', 'supervisor', 'admin'], ['Upgrade to supervisor', user.role === 'admin' ? 'Upgrade to admin' : 'Downgrade to user', 'Downgrade to user'])"
          >
            {{singleUser.role}}
            <v-icon small v-if="singleUser.role === 'user'">mdi-account-arrow-up</v-icon>
            <v-icon small v-else-if="singleUser.role === 'supervisor'">mdi-account-arrow-up</v-icon>
            <v-icon small v-else>mdi-account-arrow-down</v-icon>
          </b>
          &middot;
          contribution: <b class="orange--text">{{singleUser.contribution}}</b>
          &middot;
          Newsletter:
          <v-icon small
            :color="singleUser.news === false ? 'error' : singleUser.news === true ? 'green' : 'grey'"
            :title="singleUser.news === false ? 'Opt-out' : singleUser.news === true ? 'Opt-in' : 'Has not yet decided'"
          >
            mdi-{{singleUser.news === false ? 'email-off' : 'email-check'}}
          </v-icon><br>
          <div v-if="user.role === 'admin'">
            ID: <pre class="grey--text inline">{{$vuetify.breakpoint.xs ? $helpers.truncate(singleUser.uid, 22, '…') : singleUser.uid}}</pre>
            <Copy :data="singleUser.uid" opacity=".75" dataName="user ID"></Copy>
            <a
              target="_blank"
              class="no-underline"
              :title="`Open Firebase document of ${singleUser.name}`"
              :href="`https://console.firebase.google.com/project/dbcatourne/firestore/data/~2Fusers~2F${singleUser.uid}`"
            >
              <v-icon small class="orange--text ml-2">mdi-firebase</v-icon>
            </a>
          </div>
          <small>
            Created on: {{singleUser.createdOn ? $helpers.fbTimeToString(singleUser.createdOn, "DD.MM.YY - HH:mm") : '---' }}
            &middot;
            last login: {{singleUser.lastLogin ? $helpers.fbTimeToString(singleUser.lastLogin, "DD.MM.YY - HH:mm") : '---' }}<br>
          </small>
        </div>
      </div>
    </div>
    <div v-if="!users.length">
      No users to show.
    </div>
  </div>
</template>

<script>
import { db } from '../firebase'
import Copy from '@/components/Copy'

  export default {
    name: 'Users',
    props: {
      user: Object,
      settings: Object,
    },
    components: {
      Copy
    },

    data () {
      return {
        success: false,
        somethingWrong: false,
        loading: false,
        userConfirmDelete: '',
        users: [],
        unsubscribeUsers: []
      }
    },

    created() {
      // Get users
      this.getUsers('createdOn', 'desc');
    },

    computed: {
      totalContribution() {
        let localTotal = 0;
        this.users.forEach(function(element) {
            localTotal += element.contribution;
          });
        return localTotal;
      }
    },

    beforeDestroy() {
      if(typeof this.unsubscribeUsers === 'function') this.unsubscribeUsers();
    },

    methods: {
      getUsers(orderBy, sort) {
        // Terminate earlier snapshots if existing
        if(typeof this.unsubscribeUsers === 'function') {
          this.unsubscribeUsers();
        }
        // Circumvent doubled identical "orderBy" createdOn
        let secondary = orderBy === 'createdOn' ? 'email' : 'createdOn';
        secondary = orderBy === 'deletedUser' ? 'editedOn' : secondary;
        let where = false;
        
        // Do not show other users with these fitlers:
        if(orderBy === 'public' || orderBy === 'kicked' || orderBy === 'deleted') {
          where = orderBy;
          orderBy = 'lastLogin';
        }

        this.unsubscribeUsers = db.collection('users')
        .orderBy(orderBy, sort)
        .orderBy(secondary, 'desc');

        if(where) {
          this.unsubscribeUsers = this.unsubscribeUsers.where(where, '==', true)
        }
        
        this.unsubscribeUsers = this.unsubscribeUsers.onSnapshot(querySnapshot => {
          let newData = [];
          querySnapshot.forEach(doc => {
            let f = doc.data();
            f.id = doc.id;
            if(orderBy === 'deletedUser' || !f.deletedUser) newData.push(f);
          });
          this.users = newData;
        });
      },

      setRoleForUser(newRole, userId) {
        if(userId === this.user.uid) {
          this.$toasted.global.error({msg:'Cannot change yourself, dummy ;)'});
          return
        }
        if(this.user.role === 'supervisor' && newRole === 'user') {
          this.$toasted.global.error({msg:'Cannot downgrade to user as supervisor'});
          return
        }
        if(this.user.role === 'supervisor' && newRole === 'admin') {
          // this.$toasted.global.error({msg:'Cannot upgrade to admin as supervisor'});
          this.updateUserField('role', userId, 'user');
          return
        }
        this.updateUserField('role', userId, newRole);
      },

      updateUserField(fieldName, userId, value) {
        console.log(userId);
        this.$store.dispatch('updateField', {'collection':'users', 'document':userId,'field':fieldName, 'data': value}).then(() => {
          if(fieldName) {
              this.$toasted.global.success({msg:`Updated user ${fieldName}`});
          } else {
              this.$toasted.global.info({msg:`Updated user ${fieldName}`});
          }
        }).catch(error => {
          console.log(error);
          console.error(error.message);
          this.$toasted.global.error({msg:error.message});
          return;
        });
      },

      async userDelete(userId, roleOfUserToDelete) {
        // console.log(userId)
        this.userConfirmDelete = '';
        if(this.user.role === 'supervisor' && roleOfUserToDelete === 'admin') {
          this.$toasted.global.error({msg:'Cannot delete an admin as supervisor'});
          return
        }
        this.$helpers.copyClipBoard(userId, '..forwarding to firebase and User ID');
        await this.$helpers.sleep(1250);
        await db.collection('users').doc(userId).delete().then(function() {
          window.open('https://console.firebase.google.com/project/dbcatourne/authentication/users', '_blank');
        }).catch(function(error) {
          throw error;
        });

      },
    },
  }
</script>
