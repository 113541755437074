<template>
  <div class="grey lighten-4 fill-width fill-height">
    <div ref="hero" :style="`background-image: url('${randomImg}'); ${styles}`" class="hero fill-width fill-height" @click="getPseudoRandomImg()">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      styles: '',
      imageIndex: 0,
      imageCounter:0,
      randomImg: '',
      randomImgs: [
        require('@/assets/catourne_S1.png'),
        require('@/assets/catourne_S2.png'),
        require('@/assets/catourne_S3.png'),
        require('@/assets/catourne_S4.png'),
        require('@/assets/catourne_S5.png'),
        require('@/assets/catourne_S6.png'),
        require('@/assets/catourne_S7.png'),
      ]
    }
  },

  /* firebase: {
    persons: db.collection('persons'),
  }, */

  created() {
    this.getPseudoRandomImg(true);
    // get all documents from live collection
    /* db.collection('persons')
      //.where('user_id', '==', firebaseUser.uid)
      .onSnapshot(querySnapshot => {
        let newData = [];
        querySnapshot.forEach(doc => {
          let f = doc.data();
          f.id = doc.id;
          newData.push(f);
        });
        this.persons = newData;
      });

    // get one specific live document and some of its contents
    db.collection("texts")
    .doc("about")
    .onSnapshot(doc => {
        //var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        let data = doc.data();
        //console.log("Data: ", data);
        // this.about = {"me": data.me, "email": data.email};
        this.about = data;
    }); */
  },
  methods: {
    // swap backgrounds
    async getPseudoRandomImg(hourly) {
      if(hourly) {
        this.styles = `transform: rotate(${this.$helpers.randomBetween(-60, 60)/10}deg); `;
        let currentHour = new Date().getHours();
        currentHour /= 3;
        this.imageIndex = Math.floor(currentHour) % this.randomImgs.length;
        this.randomImg = this.randomImgs[this.imageIndex];
      } else {
        let newRandom = this.$helpers.randomBetween(0, this.randomImgs.length-1);
        // do not display same as before
        if(this.imageIndex != newRandom) {
          let everyOther = this.imageCounter % 2 === 0;
          this.imageCounter++;
          this.$refs["hero"].classList.add('fadeOutFast');
          await this.$helpers.sleep(250);
          this.styles = `transform: rotate(${this.$helpers.randomBetween(everyOther ? -60 : 5, everyOther ? 5 : 60)/10}deg); `;
          this.imageIndex = newRandom
          this.randomImg = this.randomImgs[this.imageIndex];
          this.$refs["hero"].classList.remove('fadeOutFast');
          this.$refs["hero"].classList.add('fadeInFast');
          await this.$helpers.sleep(250);
          this.$refs["hero"].classList.remove('fadeInFast');
        } else {
          this.getPseudoRandomImg();
        }
      }
    }
  },
}
</script>

<style scoped>
  .hero {
    background-size: calc(10vh + 30vw);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: all .5s ease;
  }
</style>