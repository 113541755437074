<template>
    <li :class="classNames">
      <div
        v-if="data.name !== 'add' && !edit"
        @click="$emit('clicked', data.id); $event.preventDefault()"
        class="pointer"
        :class="data.id === selected ? 'op-50 secondary--text selected' : ''"
      >
        <v-icon :small="!data.hasChildren" :class="data.hasChildren ? 'primary--text mr-1' : 'mx-1'"
        >mdi-{{ data.icon ? data.icon : 'rhombus-medium' }}</v-icon>
        <!-- {{ data.order }}.  -->
        {{ data[keyName][$i18n.locale] ? data[keyName][$i18n.locale] : data[keyName] }}

        <v-chip v-if="!hideDetails" dense small>{{ data.amountOfProps }}</v-chip>
        <v-btn v-if="edit" icon small class="ml-2 hover primary" @click="$emit('setupEditCategory', data); $event.stopPropagation()">
          <v-icon small class="white--text">mdi-pencil</v-icon>
        </v-btn>
      </div>

      <ul v-if="data.children" :class="classNames">
        <ListItem
          v-for="(val, k) in data.children"
          :key="k"
          :data="val"
          :edit="edit"
          :keyName="keyName"
          :hideDetails="hideDetails"
          :classNames="classNames"
          :selected="selected"
          @setupEditCategory="$emit('setupEditCategory', $event)"
          @addChild="$emit('addChild', $event)"
          @clicked="$emit('clicked', $event)"
        />
       <!--  <li :class="classNames" class="mb-4" @click="$emit('addChild', data); $event.preventDefault()" v-if="edit && data.hasChildren">
          <div v-if="edit" class="pointer caption hover secondary--text">
            <v-icon class="secondary--text mx-1" small>
                mdi-plus-circle
              </v-icon>
            {{ $t("Add category to")}} "{{ data[keyName][$i18n.locale] ? data[keyName][$i18n.locale] : data[keyName] }}"..
          </div>
        </li> -->
      </ul>
    </li>
  </template>
  
  <script>
  // import ListItem from "../components/ListItem.vue"
  
  export default {
    name: "ListItem",
    components: {
      // ListItem,
    },
    props: {
      data: Object,
      edit: Boolean,
      hideDetails: Boolean,
      classNames: String,
      keyName: String,
      selected: String,
    },
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
      isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
      }
    }
  };
  </script>

<style scoped>
li>div:hover, .selected {
  background-color: rgba(125,125,125,.125);
  border-radius: 1em;
}
</style>