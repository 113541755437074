<template>
    <div>
        <div class="overline primary--text">Props</div>
        <v-row>
          <v-col v-if="prop && Object.keys(prop).length" class="pa-3" cols="12">
            <div class="mb-4">name: {{ prop.name[$i18n.locale] ? prop.name[$i18n.locale] : '-' }}</div>

            <div class="mb-4">categories: {{ prop.categories }}</div>

            <div class="mb-4">tags: {{ prop.tags }}</div>

            <div class="mb-4">description: {{ prop.description[$i18n.locale] ? prop.description[$i18n.locale] : '-' }}</div>

            <div class="mb-4">defects: {{ prop.defects[$i18n.locale] ? prop.defects[$i18n.locale] : '-' }}</div>

            <div class="mb-4">aiDescription: {{ prop.aiDescription[$i18n.locale] ? prop.aiDescription[$i18n.locale] : '-' }}</div>

            <div class="mb-4">note: {{ prop.note }}</div>

            <div class="mb-4">amount: {{ prop.amount }}</div>

            <div class="mb-4">images: {{ prop.images.length }}</div>

            <div class="mb-4">available: {{ prop.available }}</div>

            <div class="mb-4">display: {{ prop.display }}</div>

            <div class="mb-4">archived: {{ prop.archived }}</div>

            <div class="mb-4">deleted: {{ prop.deleted }}</div>

            <div class="mb-4">dimensions: {{ prop.dimensions.l }}&times;{{ prop.dimensions.b }}&times;{{ prop.dimensions.h }}, {{ prop.dimensions.l }}</div>

            <hr>
            <Gallery
              v-if="prop.images ? prop.images.length : false"
              :auth="auth" :user="user" :settings="settings"
              :width="$helpers.breakpointChooser($vuetify.breakpoint, [100,33.33,25,20,12.5])"
              containerClasses="mt-4"
              contain
              :images="prop.images"
              :edit="editImages"
            ></Gallery>
            <br>
            <br>

            <v-btn to="/collection">{{ $t('Back') }}</v-btn>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <hr>
            <pre>{{ prop }}</pre>
          </v-col>
        </v-row>
        <!-- @delete="" -->
    </div>
</template>

<script>
// import BrightnessSwitcher from "./BrightnessSwitcher.vue"
import { db } from "../firebase";
import Gallery from "../components/Gallery.vue"

export default {
  name: 'Collection',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    menu: Boolean,
  },
  components: {
    Gallery,
  },
  created() {
    console.log(this.$route.params.id)
    if(this.$route.params.id) {
      this.loadProp(this.$route.params.id);
    }
  },
  beforeDestroy() {
    // WHY?? SHOULD I NOT KEEP THAT IN MEMORY? if is function on created, do nothing?
    // save to global?
    if (typeof this.unsubscribeEditProp === "function")
      this.unsubscribeEditProp();
  },
  data() {
    return {
      prop: {},
      editImages: false,  // do not need this now
    }
  },
  watch: {
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },

  methods: {
    async loadProp(propId) {
      if (typeof this.unsubscribeEditProp === "function") {
        this.unsubscribeEditProp();
      }
      this.unsubscribeEditProp = await db.collection("props")
      .doc(propId)
      .onSnapshot(doc => {
        let data = doc.data();
        this.prop = data;
      });
    },
  },
};
</script>