<template>
  <div>
    <v-text-field
      v-model="search"
      :placeholder="
        `${$t('Search categories in')} ${$i18n.availableLocales
          .map((el) => $t(`locale.${el}`))
          .join(', ')}`
      "
      @input="handleSearch"
      filled
      dense
      hide-details
      clearable
      :append-icon="search ? '' : 'mdi-filter-variant'"
      clear-icon="mdi-close-circle"
    ></v-text-field>
    
    <v-skeleton-loader
      v-if="!Object.keys(categories).length"
      class="my-4"
      type="paragraph, sentences, text, text, sentences, paragraph@3"
    ></v-skeleton-loader>

    <!-- :items="Object.values(nestedCategories).sort((a, b) => a.order - b.order)" -->
    <v-treeview
      ref="tree"
      class="superdense mt-4"
      :items="nestedCategories"
      :search="search"
      expand-icon="mdi-chevron-down"
      activatable
      hoverable
      dense
      rounded
      open-on-click
      return-object
      @update:open="openCollection($event[0] ? $event[0].id : null, $event[0] ? $event[0].parentId : null)"
      @update:active="clickOnCategory($event[0] ? $event[0].id : null, $event[0] ? $event[0].parentId : null)"
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="item.name !== 'add'" :class="!Object.keys(item.children).length ? 'secondary--text' : 'primary--text'">
          mdi-{{ item.icon ? item.icon : "rhombus-medium" }}
        </v-icon>
        <v-icon v-else class="secondary--text mx-1" small>
          mdi-plus-circle
        </v-icon>
      </template>

      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:label="{ item, active }">
        <!-- <div @click="active ? [clickOnCategory(item.id, item.parentId), $event.stopPropagation()] : null"> -->
        <!-- this is only when category is already 'selected' and the @update:active is not returning an object -->
        <div @click="active ? [clickOnCategory(item.id, item.parentId), $event.stopPropagation()] : null">
        <!-- <div > -->
        <!-- <div @click="clickOnCategory(categories[item.id], categories[item.parentId]); $event.stopPropagation()"> -->
          <span v-if="edit && item.name === 'add'" class="caption hover secondary--text">
            {{ categories[item.parentId] ? `${$t('Add category to')} '${categories[item.parentId].name[$i18n.locale]}'` : $t('Add new main category') }}
            <!-- {{ categories[item.id].nameTranslated[$i18n.locale] }} -->
            ..
          </span>
          <span v-else-if="item.name !== 'add'">
            <!-- {{ item.name[$i18n.locale] }}   -->
            {{ item.nameTranslated[$i18n.locale] }} <!-- (id: {{ item.id }}, path: [{{ item.path.join('/') }}]) -->
            <v-chip dense small>{{  item.amountOfProps }}</v-chip>
            <v-btn v-if="edit" icon small class="ml-2 hover primary" @click="setupEditCategory(item.id, item.parentId); $event.stopPropagation()">
              <v-icon small class="white--text">mdi-pencil</v-icon>
            </v-btn>
          </span>
        </div>
      </template>
    </v-treeview>

    <!-- 
      <pre v-if="categories['K-658MD']">categories['K-658MD'].name['de']: {{ categories['K-658MD'].name['de'] }}</pre>
      <pre v-if="categories['K-666']">categories['K-666'].name['de']: {{ categories['K-666'].name['de'] }}</pre>
      <hr>
      <pre>nestedCategories: {{ nestedCategories }}</pre>
      <hr>
      <pre>categories: {{ categories }}</pre>
      <hr>
    -->

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="750"
      persistent
    >
      <!-- TODO: make component -->
      <v-card>
        <v-card-title class="primary--text grey darken-4">
          <v-icon class="primary--text mr-2">mdi-file-tree</v-icon>
          {{ editCategory ? $t('Edit category') : $t('New category')}}
        </v-card-title>
        <v-card-subtitle>
          <!-- TODO: FIXME -->
        <!-- <Breadcrumbs classNames="pt-8" :crumbs="newCategory.path" :crumbNames="categoryNames"></Breadcrumbs> -->
      </v-card-subtitle>

      <!-- <pre>newCategory: {{ newCategory  }}</pre> -->


      <!-- PARENT CATEGORY -->
      <v-card-text>
        <!-- TODO: make component -->
        <!-- TODO: make possible to change category laters -->
        <v-menu v-if="!editCategory" offset-y close-on-click>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on">
              {{ $t('Move category') }}
              ({{ categories[newCategory.parentId] ? categories[newCategory.parentId].name[$i18n.locale] : 'main' }}):
              <v-btn class="primary" icon>
                <v-icon color="white">mdi-format-text-wrapping-wrap</v-icon>
              </v-btn>
            </v-chip>
          </template>
          <v-list>
            <li class="noList pl-3 grey--text">{{ $t('Choose a new category:') }}</li>
            <ul class="noList pa-2 mb-4">
              <ListItem
                v-for="(value, key) in nestedCategories.filter(x => x.name !== 'add')"
                classNames="noList pr-1 my-1"
                :key="'list-'+key"
                :auth="auth" :user="user" :settings="settings"
                keyName="nameTranslated"
                :hideDetails="true"
                :selected="newCategory.parentId"
                :edit="false"
                :data="value"
                @clicked="setNewCategory($event)"
              ></ListItem>
            </ul>
          </v-list>
        </v-menu>

        <!-- ICONS -->
        <!-- https://codepen.io/fferz/pen/XWpEQVB -->
        <v-chip>
          {{ $t('Set icon') }}:
          <IconSelector :key="editOrNewCategoryId" :textfield="false" @selectedIcon="newCategory.icon = $event.replace('mdi-','')" :preSelected="newCategory.icon"></IconSelector>
        </v-chip>

        <TextfieldAutotranslate
          :auth="auth" :user="user" :settings="settings"
          :triggerTranslations="triggerTranslations"
          @translated="translationFinished['categoryName'] = $event"
          :data="newCategory.name" 
          classNames=""
          :showAllLanguages="translationFinished['categoryName'] && waitForLanguageConfirmation"
          classMainContainer=""
          initialRowHeight="1"
          :dense="true"
          :oneLine="true"
          prefix="item"
          placeholder="Name of new tag"
        ></TextfieldAutotranslate>

          <v-checkbox
            v-model="newCategory.hasChildren"
            :label="$t(`Is a collection of further categories`)"
          ></v-checkbox>

        </v-card-text>

        <v-card-actions class="grey darken-4">
          <v-btn v-if="editCategory" @click="deleteCategory(newCategory.id);" class="" color="error" :disabled="loading">
            <v-icon class="mr-2">mdi-delete</v-icon>
            {{ $t("Delete") }}
          </v-btn>

          <a
            v-if="editCategory && user.role === 'admin'"
            target="_blank"
            class="mx-2 no-underline"
            :title="`Open Firebase document of this Category`"
            :href="`https://console.firebase.google.com/project/dbcatourne/firestore/data/~2Fsettings~2Fcategories`"
          >
            <v-icon class="orange--text">mdi-firebase</v-icon>
          </a>

          <v-spacer></v-spacer>

          <v-checkbox
            class="mx-2"
            :disabled="loading"
            v-model="waitForLanguageConfirmation"
            :label="$t(`Wait for translation`)"
          ></v-checkbox>

          <v-btn v-if="loading && waitForLanguageConfirmation" @click="waitForLanguageConfirmation = false; confirmTranslation = false; loading = false" class="" color="grey">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            {{ $t("Cancel translation") }}
          </v-btn>
          <v-btn v-else @click="clearNewCategory(); editCategory = false; editOrNewCategoryId = ''; dialog = false;" class="" color="">
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t("Close") }}
          </v-btn>

          <v-btn v-if="loading && waitForLanguageConfirmation" @click="confirmTranslation = true;" class="" color="green">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            {{ $t("Confirm translation?") }}
          </v-btn>

          <v-btn v-if="!(loading && waitForLanguageConfirmation)" @click="saveCategory(newCategory)" class="" color="primary" :disabled="disabledSaveBtn || loading" :loading="loading">
            <v-icon class="mr-2">mdi-content-save</v-icon>
            {{ loading? `${$t('Loading')}..` : editCategory ? $t("Edit") : $t("Save new") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../firebase";
import IconSelector from "../components/IconSelector.vue"
import TextfieldAutotranslate from "../components/TextfieldAutotranslate.vue"
import Breadcrumbs from "../components/Breadcrumbs.vue"
import ListItem from "../components/ListItem.vue"

export default {
  props: {
    auth: Boolean,
    edit: Boolean,
    user: Object,
    settings: Object,
  },
  components: {
    IconSelector,
    TextfieldAutotranslate,
    // eslint-disable-next-line vue/no-unused-components
    Breadcrumbs,
    ListItem,
  },
  data() {
    return {
      dialog: false,
      categories: [],
      nestedCategories: [],
      unsubscribeCategories: [],
      newCategory: {},
      search: "",
      editOrNewCategoryId: "",
      editCategory: false,
      translationFinished: {
        'categoryName': false
      },
      loading: false,
      waitForLanguageConfirmation: false,
      confirmTranslation: false,
      triggerTranslations: '',
    };
  },
  async created() {
    this.getCategories();
  },

  beforeDestroy() {
    // WHY?? SHOULD I NOT KEEP THAT IN MEMORY? if is function on created, do nothing?
    // save to global?
    if (typeof this.unsubscribeCategories === "function")
      this.unsubscribeCategories();
  },

  computed: {
    disabledSaveBtn() {
      // FIXME somehow this.newCategory is null (therefore key .name fails) on load because of window that is loaded only when needed?
      let nameIsNotEmpty = false;
      if(this.dialog) {
        nameIsNotEmpty = !Object.values(this.newCategory.name).some(value => value !== "")
      }
      return (this.newCategory.icon && this.newCategory.icon.length == 0) || nameIsNotEmpty;
    }
  },

  methods: {
    /* FIXME TODO:
    - if new cat., choose category menu
    - if edit cat, move category = edit its parent
    - if delete cat, re-order items
    - breadcrumbs...?
    */
    getCategories() {
      // Terminate earlier snapshots if existing
      if (typeof this.unsubscribeCategories === "function") {
        this.unsubscribeCategories();
      }
      this.unsubscribeCategories = db.collection("settings")
      .doc("categories")
      .onSnapshot(doc => {
        let data = doc.data();
        this.categories = data;
      });
    },

    createNestedMenuStructure(categoriesObject) {
      const menuMap = new Map();
      // First, map all items by their id for easy access
      for (const [id, category] of Object.entries(categoriesObject)) {
          menuMap.set(id, {
            // ...category,  // If you want it all
            amountOfProps: category.amountOfProps,
            order: category.order,
            hasChildren: category.hasChildren,
            icon: category.icon,
            parentId: category.parentId,
            path: ['solve this'],
            nameTranslated: category.name,
            name: Object.values(category.name).join(', '),  // because treeview search looks for key .name
            id,
            children: []
          });
      }

      // Then, build the tree structure
      const rootItems = [];
      // eslint-disable-next-line no-unused-vars
      for (const [id, category] of menuMap.entries()) {
        if (category.parentId) {
          const parent = menuMap.get(category.parentId);
          if (parent) {
            parent.children.push(category);
          }
        } else {
          rootItems.push(category);
        }
      }

      // eslint-disable-next-line no-unused-vars
      for (const [id, category] of menuMap.entries()) {
        if (category.children.length > 0) {
          category.children.sort((a, b) => a.order - b.order);
        }
        // Add a custom child 'add' to each category
        if(category.hasChildren && this.edit) {
          category.children.push({ name: 'add', parentId: id, id: 'add-'+id });
        }
      }

      rootItems.sort((a, b) => a.order - b.order);
      if(this.edit) rootItems.push({ name: 'add', parentId: "", id: 'add-main' });
      return rootItems;
    },

    openCollection(id, parentId){
      // Gets all currently opened folders
      if(id) {
        console.log('Clicked openCollection. id: ', id, 'parentId: ', parentId)
      } else {
        // Sadly, no object is returned from $event.
        console.log('Clicked openCollection to close.')
      }
    },

    clickOnCategory(id, parentId){
      // console.log('Clicked clickOnCategory: ', id, parentId);
      if(id.startsWith("add-")) {
        this.setupNewCategory(parentId)
      } else {
        console.log('clickedCategory + emit: "', id, '"')
        this.$emit('clickedCategory', id)
      }
      return;
    },
    
    setupNewCategory(parentId) {
      this.editCategory = false;
      this.clearNewCategory();
      this.newCategory.id = this.$helpers.createArticleNumber(5, 'K-');
      this.newCategory.parentId = parentId;
      let childrenOfParent = this.$helpers.findKeyRecursive(this.nestedCategories.filter(obj => obj.name !== 'add'), "id", parentId, "children")
      // Has a parent? Eg. is top most category?
      let siblings = childrenOfParent ? childrenOfParent.length-1 : this.nestedCategories.length-1;
      console.log('siblings:', siblings);
      this.newCategory.order = siblings;
      this.editOrNewCategoryId = this.newCategory.id;
      console.log(`Add new category '${this.newCategory.id}' to parent '${parentId}'`);
      this.dialog = true;
    },

    setupEditCategory(id, parentId) {
      this.editCategory = true;
      this.newCategory = JSON.parse(JSON.stringify(this.categories[id]));  // Make non reactive
      this.newCategory.id = id;
      // To trigger re-render of breadcrumb / icon selector:
      this.editOrNewCategoryId = this.categories[id].id;
      console.log(`Edit category '${id}' in '${parentId}'`);
      this.dialog = true;
    },

    clearNewCategory() {
      this.newCategory = {
        "order": -1,
        "amountOfProps": 0,
        "name": this.$i18n.availableLocales.reduce((acc,curr)=> (acc[curr]='',acc),{}),
        "icon": "",
        "id": "",
        "hasChildren": false,
        "path": [],
      }
    },

    // eslint-disable-next-line no-unused-vars
    async saveCategory(data) {
      console.log("data: ", data);
      this.loading = true;
      // Wait for translations
      if(!Object.values(this.translationFinished).every(value => value === true)) {
        // trigger translation if some text is not yet translated
        this.triggerTranslations = this.$helpers.createUid();
        while(!Object.values(this.translationFinished).every(value => value === true)) {
          console.log("wait for translation..");
          await this.$helpers.sleep(50);
        }
      }

      // if user wants to double check the translations
      // TODO:  what if abbrechen? or close? some loading this are out of order
      if(this.waitForLanguageConfirmation) {
        while(!this.confirmTranslation && this.waitForLanguageConfirmation) {
          console.log("wait for user to press the button");
          await this.$helpers.sleep(100);
        }
        if(!this.waitForLanguageConfirmation) {
          // User pressed cancel
          console.log("User pressed cancel");
          this.loading = false;
          this.waitForLanguageConfirmation = true;
          Object.keys(this.translationFinished).forEach(key => this.translationFinished[key] = false);
          return;
        }
      }
      // Editing a category
      if(this.editCategory) {
        await db.collection("settings").doc('categories').update({[data.id]: data}).then(async function() {
          console.log("saved!");
          return true
        }).catch(function(error) {
          console.log(error);
          throw error;
        });
      } else {
        await db.collection("settings").doc("categories").set({...this.categories, [data.id]: data}).then(async function() {
          return true;
        }).catch(function(error) {
          console.log("error in settings/categories!", error);
          throw error;
        });
      }

      // this.editCategory = false;
      this.confirmTranslation = false;
      this.loading = false;
      Object.keys(this.translationFinished).forEach(key => this.translationFinished[key] = false);
      this.clearNewCategory();
      this.$toasted.global.success({msg: this.editCategory ? this.$t(`Edited category`) : this.$t(`New category created!`)});
      this.dialog = false;
    },

    setNewCategory(categoryId){
      // FIXME: this does not visually update in the dialog. some nextTick magic or some shit
      if(this.categories[categoryId].hasChildren) {
        this.newCategory.parentId = categoryId;  
      } else {
        this.$toasted.global.info({msg:this.$t(`This category is not a collection of further categories.`)});
      }
    },

    async deleteCategory(id) {
      // Think of the children!
      console.log("soon be goner: ", id);
      // finds only the first child, but alas..
      let isAParent = this.$helpers.findKeyRecursive(this.categories, 'parentId', id, "id")
      if(isAParent) {
        this.$toasted.global.error({msg:this.$t(`This category has children elements. Re/move them before you make them orphans!`)});
        this.dialog = false;
        return;
      }
      delete this.categories[id];
      console.log("goin thru");
      await db.collection("settings").doc("categories").set(this.categories).then(async function() {
        return true;
      }).catch(function(error) {
        console.log("error in settings/categories!", error);
        throw error;
      });

      // TODO: Update all "order" keys & values..

      this.$toasted.global.success({msg:this.$t(`Deleted category`)});
      // this.editCategory = false;
      // this.editOrNewCategoryId = ''
      this.dialog = false;
    },

    handleSearch(input) {
      if(input) {
        this.$refs.tree.updateAll(true)
      } else {
        this.$refs.tree.updateAll(false)
      }
    },
  },
  mounted() {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    categories: function(oldsearch, newsearch) {
      this.nestedCategories = this.createNestedMenuStructure(this.categories);
    },
  },



};
</script>

<style>
  .superdense .v-treeview-node .v-treeview-node__root {
    margin-top:0 !important;
    margin-bottom:0 !important;
  }
</style>