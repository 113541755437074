<template>
  <div>
    <v-card-title class="justify-center">Datenschutzerklärung</v-card-title>

    <v-card-text v-if="settings.consent && settings.consent.privacy" class="white--text italics text-center">
      Stand: {{$helpers.fbTimeToString(settings.consent.privacy, "DD.MM.YYYY - HH:mm")}}
    </v-card-text>

    <v-card-actions class="pl-0">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-heart</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-hammer-wrench</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-format-paint</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-sofa-single</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-truck-cargo-container</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-desktop-classic</v-icon>
      <v-spacer></v-spacer>
      <v-icon small color="pink">mdi-head-flash</v-icon>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-card-actions>

    <!-- GENERATED BY -->
    <!-- https://datenschutz-generator.de/ -->

    <v-card-text class="white--text">
      <h2 id="m1870">Einleitung</h2>
      <p>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
        aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
        auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
        welchem Umfang im Rahmen der Bereitstellung unserer Applikation
        verarbeiten.
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <h2>Inhaltsübersicht</h2>
      <ul class="index">
        <li><a class="index-link" href="#m1870">Einleitung</a></li>
        <li><a class="index-link" href="#m3">Verantwortlicher</a></li>
        <li>
          <a class="index-link" href="#mOverview"
            >Übersicht der Verarbeitungen</a
          >
        </li>
        <li>
          <a class="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a>
        </li>
        <li><a class="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
        <li>
          <a class="index-link" href="#m25"
            >Übermittlung von personenbezogenen Daten</a
          >
        </li>
        <li>
          <a class="index-link" href="#m24"
            >Datenverarbeitung in Drittländern</a
          >
        </li>
        <li><a class="index-link" href="#m12">Löschung von Daten</a></li>
        <li>
          <a class="index-link" href="#m225"
            >Bereitstellung des Onlineangebotes und Webhosting</a
          >
        </li>
        <li>
          <a class="index-link" href="#m367"
            >Registrierung, Anmeldung und Nutzerkonto</a
          >
        </li>
        <li><a class="index-link" href="#m432">Community Funktionen</a></li>
        <li>
          <a class="index-link" href="#m104">Blogs und Publikationsmedien</a>
        </li>
        <li>
          <a class="index-link" href="#m408">Umfragen und Befragungen</a>
        </li>
        <li>
          <a class="index-link" href="#m328"
            >Plugins und eingebettete Funktionen sowie Inhalte</a
          >
        </li>
        <li>
          <a class="index-link" href="#m723"
            >Management, Organisation und Hilfswerkzeuge</a
          >
        </li>
        <li>
          <a class="index-link" href="#m15"
            >Änderung und Aktualisierung der Datenschutzerklärung</a
          >
        </li>
        <li>
          <a class="index-link" href="#m10"
            >Rechte der betroffenen Personen</a
          >
        </li>
        <li><a class="index-link" href="#m42">Begriffsdefinitionen</a></li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>
      <p>
        Fabian Lüscher Filmkulissen<br>
        Fabian Lüscher<br>
        St. Gallerstrasse 27<br>8400
        Winterthur, Schweiz
      </p>
      E-Mail-Adresse:
      <p><a href="mailto:info@fluescher.ch">info@fluescher.ch</a></p>
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
        die Zwecke ihrer Verarbeitung zusammen und verweist auf die
        betroffenen Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Bestandsdaten.</li>
        <li>Kontaktdaten.</li>
        <li>Inhaltsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-/Kommunikationsdaten.</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Kommunikationspartner.</li>
        <li>Nutzer.</li>
        <li>Teilnehmer.</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Büro- und Organisationsverfahren.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
        <li>Feedback.</li>
        <li>
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>Informationstechnische Infrastruktur.</li>
      </ul>
      <h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
      <p>
        Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
        DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
        nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
        Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
        können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
        maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
        mit.
      </p>
      <ul>
        <li>
          <strong
            >Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
            1 lit. b) DSGVO)</strong
          >
          - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
          Vertragspartei die betroffene Person ist, oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
          betroffenen Person erfolgen.
        </li>
        <li>
          <strong
            >Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong
          >
          - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern,
          überwiegen.
        </li>
      </ul>
      <p>
        Zusätzlich zu den Datenschutzregelungen der
        Datenschutz-Grundverordnung gelten nationale Regelungen zum
        Datenschutz in der Schweiz. Hierzu gehört insbesondere das
        Bundesgesetz zum Datenschutz (DSG). Das DSG gilt insbesondere dann,
        wenn keine EU/EWG-Bürger betroffen sind und z.B. nur Daten von
        Schweizer Bürgern verarbeitet werden.
      </p>
      <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
        Berücksichtigung des Stands der Technik, der Implementierungskosten
        und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
        sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
        Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
        geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
        angemessenes Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der
        Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
        Kontrolle des physischen und elektronischen Zugangs zu den Daten als
        auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
        Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
        Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
        die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
        gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
        Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
        sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
        Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
      </p>
      <p>
        TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
        übermittelten Daten zu schützen, nutzen wir eine TLS-Verschlüsselung.
        Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https://
        in der Adresszeile Ihres Browsers.
      </p>
      <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
      <p>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
        vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
        selbstständige Organisationseinheiten oder Personen übermittelt oder
        sie ihnen gegenüber offengelegt werden. Zu den Empfängern dieser Daten
        können z.B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter
        von Diensten und Inhalten, die in eine Webseite eingebunden werden,
        gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und
        schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die
        dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
      </p>
      <h2 id="m24">Datenverarbeitung in Drittländern</h2>
      <p>
        Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen
        Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder
        die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
        oder der Offenlegung bzw. Übermittlung von Daten an andere Personen,
        Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit
        den gesetzlichen Vorgaben.
      </p>
      <p>
        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
        gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die
        Daten nur in Drittländern mit einem anerkannten Datenschutzniveau,
        vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln
        der EU-Kommission, beim Vorliegen von Zertifizierungen oder
        verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44
        bis 49 DSGVO, Informationsseite der EU-Kommission:
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
          target="_blank"
          >https://ec.europa.eu/info/la...sion-data-protection_de</a
        >).
      </p>
      <h2 id="m12">Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
        Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
        (z.B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
        sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht
        gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
        erforderlich sind, wird deren Verarbeitung auf diese Zwecke
        beschränkt. D.h., die Daten werden gesperrt und nicht für andere
        Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
        steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
        Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
        oder juristischen Person erforderlich ist.
      </p>
      <p>
        Unsere Datenschutzhinweise können ferner weitere Angaben zu der
        Aufbewahrung und Löschung von Daten beinhalten, die für die jeweiligen
        Verarbeitungen vorrangig gelten.
      </p>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>
        Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
        zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
        IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
        Funktionen unserer Online-Dienste an den Browser oder das Endgerät der
        Nutzer zu übermitteln.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
          besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
          Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
          Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
          und technischen Geräten (Computer, Server etc.).);
          Sicherheitsmaßnahmen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong
            >Bereitstellung Onlineangebot auf gemietetem Speicherplatz: </strong
          >Für die Bereitstellung unseres Onlineangebotes nutzen wir
          Speicherplatz, Rechenkapazität und Software, die wir von einem
          entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder
          anderweitig beziehen; <strong>Rechtsgrundlagen:</strong> Berechtigte
          Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
        <li>
          <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
          Zugriff auf unser Onlineangebot wird in Form von so genannten
          "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
          des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
          Regelfall IP-Adressen und der anfragende Provider gehören. Die
          Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
          werden, z.B., um eine Überlastung der Server zu vermeiden
          (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
          DDoS-Attacken) und zum anderen, um die Auslastung der Server und
          ihre Stabilität sicherzustellen;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO);
          <strong>Löschung von Daten:</strong> Logfile-Informationen werden
          für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht
          oder anonymisiert. Daten, deren weitere Aufbewahrung zu
          Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
          jeweiligen Vorfalls von der Löschung ausgenommen.
        </li>
        <li>
          <strong>Content-Delivery-Network: </strong>Wir setzen ein
          "Content-Delivery-Network" (CDN) ein. Ein CDN ist ein Dienst, mit
          dessen Hilfe Inhalte eines Onlineangebotes, insbesondere große
          Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe
          regional verteilter und über das Internet verbundener Server
          schneller und sicherer ausgeliefert werden können;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
      <p>
        Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
        werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu
        Zwecken der Bereitstellung des Nutzerkontos auf Grundlage
        vertraglicher Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten
        gehören insbesondere die Login-Informationen (Nutzername, Passwort
        sowie eine E-Mail-Adresse).
      </p>
      <p>
        Im Rahmen der Inanspruchnahme unserer Registrierungs- und
        Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
        IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
        Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
        auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger
        unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
        grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer
        Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
        hierzu.
      </p>
      <p>
        Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant
        sind, wie z.B. technische Änderungen, per E-Mail informiert werden.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten
          (z.B. Eingaben in Onlineformularen); Meta-/Kommunikationsdaten (z.B.
          Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Kundenservice; Sicherheitsmaßnahmen; Verwaltung und
          Beantwortung von Anfragen; Bereitstellung unseres Onlineangebotes
          und Nutzerfreundlichkeit.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Registrierung mit Pseudonymen: </strong>Nutzer dürfen statt
          Klarnamen Pseudonyme als Nutzernamen verwenden;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <strong>Einstellung der Sichtbarkeit von Profilen: </strong>Die
          Nutzer können mittels Einstellungen bestimmen, in welchem Umfang
          ihre Profile für die Öffentlichkeit oder nur für bestimmte
          Personengruppen sichtbar, bzw. zugänglich sind;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <strong>Löschung von Daten nach Kündigung: </strong>Wenn Nutzer ihr
          Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das
          Nutzerkonto, vorbehaltlich einer gesetzlichen Erlaubnis, Pflicht
          oder Einwilligung der Nutzer, gelöscht;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <strong>Keine Aufbewahrungspflicht für Daten: </strong>Es obliegt
          den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende
          zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer
          gespeicherte Daten des Nutzers unwiederbringlich zu löschen;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <h2 id="m432">Community Funktionen</h2>
      <p>
        Die von uns bereitgestellten Community Funktionen erlauben es Nutzern
        miteinander in Konversationen oder sonst miteinander in einen
        Austausch zu treten. Hierbei bitten wir zu beachten, dass die Nutzung
        der Communityfunktionen nur unter Beachtung der geltenden Rechtslage,
        unserer Bedingungen und Richtlinien sowie der Rechte anderer Nutzer
        und Dritter gestattet ist.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
          besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
          Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Kundenservice; Sicherheitsmaßnahmen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Speicherung von Daten zu Sicherheitszwecken: </strong>Die
          Beiträge und sonstige Eingaben der Nutzer werden zu Zwecken der
          Community- und Konversationsfunktionen verarbeitet und,
          vorbehaltlich gesetzlicher Pflichten oder gesetzlicher Erlaubnis
          nicht an Dritte herausgegeben. Eine Herausgabepflicht kann
          insbesondere im Fall von rechtswidrigen Beiträgen zu Zwecken der
          Rechtsverfolgung entstehen. Wir weisen darauf hin, dass neben den
          Inhalten der Beiträge auch deren Zeitpunkt und die IP-Adresse der
          Nutzer gespeichert werden. Dies geschieht, um zum Schutz anderer
          Nutzer und der Community angemessene Maßnahmen ergreifen zu können;
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <strong>Recht zur Löschung von Inhalten und Informationen: </strong
          >Die Löschung von Beiträgen, Inhalten oder Angaben der Nutzer ist
          nach einer sachgerechten Abwägung im erforderlichen Umfang zulässig
          soweit konkrete Anhaltspunkte dafür bestehen, dass sie einen Verstoß
          gegen gesetzliche Regeln, unsere Vorgaben oder Rechte Dritter
          darstellen; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
        <li>
          <strong>Schutz eigener Daten: </strong>Die Nutzer entscheiden
          selbst, welche Daten sie über sich innerhalb unseres Onlineangebotes
          preisgeben. Zum Beispiel, wenn Nutzer Angaben zur eigenen Person
          machen oder an Konversationen teilnehmen. Wir bitten die Nutzer ihre
          Daten zu schützen und persönliche Daten nur mit Bedacht und nur im
          erforderlichen Umfang zu veröffentlichen. Insbesondere bitten wir
          die Nutzer zu beachten, dass sie die Zugangsdaten ganz besonders
          schützen und sichere Passwörter verwenden müssen (d.h. vor allem
          möglichst lange und zufällige Zeichenkombinationen);
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <h2 id="m104">Blogs und Publikationsmedien</h2>
      <p>
        Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und
        Publikation (nachfolgend "Publikationsmedium"). Die Daten der Leser
        werden für die Zwecke des Publikationsmediums nur insoweit
        verarbeitet, als es für dessen Darstellung und die Kommunikation
        zwischen Autoren und Lesern oder aus Gründen der Sicherheit
        erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur
        Verarbeitung der Besucher unseres Publikationsmediums im Rahmen dieser
        Datenschutzhinweise.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen,
          Adressen); Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten
          (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten);
          Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Kundenservice; Feedback (z.B. Sammeln von Feedback
          via Online-Formular); Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit; Sicherheitsmaßnahmen; Verwaltung und
          Beantwortung von Anfragen.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong>Kommentare und Beiträge: </strong>Wenn Nutzer Kommentare
          oder sonstige Beiträge hinterlassen, können ihre IP-Adressen auf
          Grundlage unserer berechtigten Interessen gespeichert werden. Das
          erfolgt zu unserer Sicherheit, falls jemand in Kommentaren und
          Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen,
          verbotene politische Propaganda etc.). In diesem Fall können wir
          selbst für den Kommentar oder Beitrag belangt werden und sind daher
          an der Identität des Verfassers interessiert. Des Weiteren behalten
          wir uns vor, auf Grundlage unserer berechtigten Interessen die
          Angaben der Nutzer zwecks Spamerkennung zu verarbeiten. Auf
          derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen
          die IP-Adressen der Nutzer für deren Dauer zu speichern und Cookies
          zu verwenden, um Mehrfachabstimmungen zu vermeiden. Die im Rahmen
          der Kommentare und Beiträge mitgeteilten Informationen zur Person,
          etwaige Kontakt- sowie Webseiteninformationen als auch die
          inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer
          dauerhaft gespeichert;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m408">Umfragen und Befragungen</h2>
      <p>
        Wir führen Umfragen und Befragungen durch, um Informationen für den
        jeweils kommunizierten Umfrage- bzw. Befragungszweck, zu sammeln. Die
        von uns durchgeführten Umfragen und Befragungen (nachfolgend
        "Befragungen") werden anonym ausgewertet. Eine Verarbeitung
        personenbezogener Daten erfolgt nur insoweit, als dies zu
        Bereitstellung und technischen Durchführung der Umfragen erforderlich
        ist (z.B. Verarbeitung der IP-Adresse, um die Umfrage im Browser des
        Nutzers darzustellen oder mithilfe eines Cookies eine Wiederaufnahme
        der Umfrage zu ermöglichen).
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail,
          Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen);
          Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
          Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner;
          Teilnehmer.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Feedback (z.B. Sammeln von
          Feedback via Online-Formular).
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
      <p>
        Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
        die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
        als "Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel
        um Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
        bezeichnet als "Inhalte”).
      </p>
      <p>
        Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
        Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
        IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
        IP-Adresse ist damit für die Darstellung dieser Inhalte oder
        Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
        verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
        Auslieferung der Inhalte verwenden. Drittanbieter können ferner
        sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
        bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
        "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
        Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
        Informationen können ferner in Cookies auf dem Gerät der Nutzer
        gespeichert werden und unter anderem technische Informationen zum
        Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
        Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
        enthalten als auch mit solchen Informationen aus anderen Quellen
        verbunden werden.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B.
          besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
          Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z.B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong
          >Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
          Diensten:</strong
        >
      </p>
      <ul class="m-elements">
        <li>
          <strong
            >Einbindung von Drittsoftware, Skripten oder Frameworks (z. B.
            jQuery):
          </strong>
          Wir binden in unser Onlineangebot Software ein, die wir von Servern
          anderer Anbieter abrufen (z.B. Funktions-Bibliotheken, die wir
          zwecks Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
          verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse
          der Nutzer und können diese zu Zwecken der Übermittlung der Software
          an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch
          zur Auswertung und Optimierung ihres Angebotes verarbeiten. - Wir
          binden in unser Onlineangebot Software ein, die wir von Servern
          anderer Anbieter abrufen (z.B. Funktions-Bibliotheken, die wir
          zwecks Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
          verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse
          der Nutzer und können diese zu Zwecken der Übermittlung der Software
          an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch
          zur Auswertung und Optimierung ihres Angebotes verarbeiten;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO).
        </li>
        <li>
          <strong>Google Fonts (Bezug vom Google Server): </strong> Bezug von
          Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
          wartungsfreien und effizienten Nutzung von Schriften und Symbolen im
          Hinblick auf Aktualität und Ladezeiten, deren einheitliche
          Darstellung und Berücksichtigung möglicher lizenzrechtlicher
          Beschränkungen. Dem Anbieter der Schriftarten wird die IP-Adresse
          des Nutzers mitgeteilt, damit die Schriftarten im Browser des
          Nutzers zur Verfügung gestellt werden können. Darüber hinaus werden
          technische Daten (Spracheinstellungen, Bildschirmauflösung,
          Betriebssystem, verwendete Hardware) übermittelt, die für die
          Bereitstellung der Schriften in Abhängigkeit von den verwendeten
          Geräten und der technischen Umgebung notwendig sind;
          <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Irland;
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
          Abs. 1 S. 1 lit. f) DSGVO);<br>
          <strong>Website: </strong>
          <a href="https://fonts.google.com/" target="_blank"
            >https://fonts.google.com/</a
          >;<br>
          <strong>Datenschutzerklärung: </strong>
          <a href="https://policies.google.com/privacy" target="_blank"
            >https://policies.google.com/privacy</a
          >.
        </li>
      </ul>
      <h2 id="m723">Management, Organisation und Hilfswerkzeuge</h2>
      <p>
        Wir setzen Dienstleistungen, Plattformen und Software anderer Anbieter
        (nachfolgend bezeichnet als "Drittanbieter”) zu Zwecken der
        Organisation, Verwaltung, Planung sowie Erbringung unserer Leistungen
        ein. Bei der Auswahl der Drittanbieter und ihrer Leistungen beachten
        wir die gesetzlichen Vorgaben.
      </p>
      <p>
        In diesem Rahmen können personenbezogenen Daten verarbeitet und auf
        den Servern der Drittanbieter gespeichert werden. Hiervon können
        diverse Daten betroffen sein, die wir entsprechend dieser
        Datenschutzerklärung verarbeiten. Zu diesen Daten können insbesondere
        Stammdaten und Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen,
        sonstigen Prozessen und deren Inhalte gehören.
      </p>
      <p>
        Sofern Nutzer im Rahmen der Kommunikation, von Geschäfts- oder anderen
        Beziehungen mit uns auf die Drittanbieter bzw. deren Software oder
        Plattformen verwiesen werden, können die Drittanbieter Nutzungsdaten
        und Metadaten zu Sicherheitszwecken, zur Serviceoptimierung oder zu
        Marketingzwecken verarbeiten. Wir bitten daher darum, die
        Datenschutzhinweise der jeweiligen Drittanbieter zu beachten.
      </p>
      <ul class="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Inhaltsdaten (z.B.
          Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten);
          Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner; Nutzer
          (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Kundenservice; Büro- und Organisationsverfahren.
        </li>
      </ul>
      <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie, sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die
        Datenschutzerklärung an, sobald die Änderungen der von uns
        durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
        informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
        Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
        Benachrichtigung erforderlich wird.
      </p>
      <p>
        Sofern wir in dieser Datenschutzerklärung Adressen und
        Kontaktinformationen von Unternehmen und Organisationen angeben,
        bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
        können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
      </p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>
        Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
        sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
      </p>
      <ul>
        <li>
          <strong
            >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
            Ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten, die
            aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
            Widerspruch einzulegen; dies gilt auch für ein auf diese
            Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
            personenbezogenen Daten verarbeitet, um Direktwerbung zu
            betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten zum
            Zwecke derartiger Werbung einzulegen; dies gilt auch für das
            Profiling, soweit es mit solcher Direktwerbung in Verbindung
            steht.</strong
          >
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
          Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
          Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
          werden und auf Auskunft über diese Daten sowie auf weitere
          Informationen und Kopie der Daten entsprechend den gesetzlichen
          Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
          gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
          betreffenden Daten oder die Berichtigung der Sie betreffenden
          unrichtigen Daten zu verlangen.
        </li>
        <li>
          <strong
            >Recht auf Löschung und Einschränkung der Verarbeitung:</strong
          >
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
          verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
          bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
          Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
          Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
          Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
          und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
          einen anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
          unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
          gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
          mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
          Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
          Vorgaben der DSGVO verstößt.
        </li>
      </ul>
      <h2 id="m42">Begriffsdefinitionen</h2>
      <p>
        In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
        Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der Begriffe
        sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
        gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
        Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die
        Begriffe sind alphabetisch sortiert.
      </p>
      <ul class="glossary">
        <li>
          <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“
          sind alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person (im Folgenden "betroffene
          Person“) beziehen; als identifizierbar wird eine natürliche Person
          angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
          zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
          Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
          oder mehreren besonderen Merkmalen identifiziert werden kann, die
          Ausdruck der physischen, physiologischen, genetischen, psychischen,
          wirtschaftlichen, kulturellen oder sozialen Identität dieser
          natürlichen Person sind.
        </li>
        <li>
          <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die
          natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
          bezeichnet.
        </li>
        <li>
          <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
          ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
          solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
          Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
          Daten, sei es das Erheben, das Auswerten, das Speichern, das
          Übermitteln oder das Löschen.
        </li>
      </ul>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  props: {
    settings: Object,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
  body {
    scroll-behavior: smooth;
  }
  .m-elements {
    /* li's */
    /* color:green */
  }
  h2 {
    font-weight: normal;
    padding: 0;
    margin: 2.75em 0 0.5em 0;
    scroll-margin-top: 100px;
    transform: translateX(-.95em);
  }
  h2:before {
    content: "# ";
    color: grey;
  }
  h3 {
    font-weight: normal;
    padding: 0;
    margin: 1em 0 0.125em 0;
  }
  ul {
    padding-bottom: 1em;
    list-style-type: '- ';
  }
  li {
  }
</style>
