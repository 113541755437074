<template>
    <!-- MOBILE HAMBURGER MENU -->
    <v-navigation-drawer
      v-model="menuLocal"
      left
      :fixed="$vuetify.breakpoint.smAndDown"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :width="$vuetify.breakpoint.xs ? '100%' : $vuetify.breakpoint.sm ? '75%' : '22%'"
    >
      
      <v-list-item :class="$vuetify.breakpoint.smAndDown ? '' : 'mt-5'">
        <v-list-item-content>
          <v-img v-if="$vuetify.breakpoint.sm" class="op-50 mt-2" height="3em" contain :class="$vuetify.theme.dark ? 'invert' : ''" src="logo.svg"></v-img>
          <img v-if="$vuetify.breakpoint.smAndDown" class="mt-4" :class="$vuetify.theme.dark ? 'invert' : 'darken-3'" src="stellarDb.svg" style="height:1.5em"
            title="Stellar [/ˈstɛlə/]: 1. Of, pertaining to, or characteristic of stars. 2. Heavenly 3. (by extension) Exceptional, exceptionally good."
          >
          <v-btn v-if="$vuetify.breakpoint.smAndDown" class="primary ma-4 absolute top right" large @click="$emit('menu');" icon>
            <v-icon color="white" large class="mx-1">mdi-close-circle</v-icon>
          </v-btn>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <div 
            v-for="link in compiledLinks"
            :key="link.title"
        >
          <v-list-item
            v-if="link.to"
            :to="link.to"
            @click.native="$emit('menu');"
            link
          >
            <v-list-item-avatar>
              <v-icon color="primary" class="mx-0">mdi-{{ link.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="pa-0">
              <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
              <v-list-item-subtitle v-if="link.tooltip.length">
              {{ $t(link.tooltip) }}
            </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item-subtitle v-else class="small grey--text mt-4 pl-4" dense>
            {{ $t(link.title) }}
          </v-list-item-subtitle>
        </div>

        <v-divider v-if="auth"></v-divider>

        <v-list-item v-if="$vuetify.breakpoint.xs">
          <LanguageSwitcher :auth="auth" :user="user" :settings="settings"></LanguageSwitcher>
          <BrightnessSwitcher :auth="auth" :user="user" :settings="settings"></BrightnessSwitcher>
        </v-list-item>

        <v-list-item v-if="auth" @click="logout()" link>
          <v-list-item-avatar>
            <v-icon color="pink" class="mx-0">mdi-logout-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- FOOTER -->
      <template v-slot:append>
        <div class="pa-2 caption">
          <span class="overline pr-1" style="line-height:.5em">Stellar&middot;DB <span style="text-transform:lowercase;">v</span>{{appVersion}}</span>
          <br>
          <span class="pr-1" v-if="$vuetify.breakpoint.smAndUp">made with <v-icon small color="rgba(255,0,255,.5)">mdi-heart</v-icon> by</span>
          <a href="https://www.telefabi.ch" target="_blank" class="no-underline"><span class=" pink--text ">telefabi.ch</span></a>
        </div>
      </template>
    </v-navigation-drawer>
</template>

<script>
import {version} from '../../package'
import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue"

export default {
  props: {
    auth: Boolean,
    user: Object,
    settings: Object,
    menu: Boolean,
  },
  components: {
    LanguageSwitcher,
    BrightnessSwitcher
  },
  data() {
    return {
      on: false,
      attrs: false,
      appVersion: version,
      links: [
        // ALLE
        {title: 'General', auth: true},
        {to: '/productions', auth: true, role: 'user', title: 'Movieproductions', icon: 'movie', tooltip: 'All orders'},
        
        // NUR INTERNA
        {to: '/finances', auth: true, role: 'supervisor', title: 'Finances', icon: 'finance', tooltip: 'Overview of financial stuff'},
        {to: '/pickups', auth: true, role: 'supervisor', title: 'Pickups & Dropoffs', icon: 'swap-horizontal-bold', tooltip: 'Scheduling pickups & returns of props'},
        {to: '/users', auth: true, role: 'supervisor', title: 'User management', icon: 'account-group', tooltip: 'All internal + external users'},
        {to: '/admin', auth: true, role: 'admin', title: 'Admin', icon: 'server', tooltip: 'Backups, Maintenance mode etc'},
        {to: '/about', auth: "both", role: 'user', title: 'About this database', icon: 'information', tooltip: 'What & how, FAQ, guidelines, whodunit? & contact'},
        
        {title: 'Collection', auth: true},
        // ALLE
        {to: '/collection', auth: true, role: 'user', title: 'All props', icon: 'briefcase', tooltip: 'Collections from A-Z'},

        // NUR INTERNA
        {to: '/newProp', auth: true, role: 'supervisor', title: 'Create new Prop', icon: 'briefcase-plus', tooltip: ''},
        {to: '/mngCategories', auth: true, role: 'supervisor', title: 'Manage categories', icon: 'file-tree', tooltip: ''},
        {to: '/mngTags', auth: true, role: 'supervisor', title: 'Manage Tags', icon: 'tag-multiple', tooltip: ''},
        
        // ALLE
        {title: 'Session & User', auth: true},
        {to: '/profile', auth: true, role: 'user', title: 'Your profile', icon: 'account-circle', tooltip: 'Email, password, avatar'},
        {to: '/login', auth: false, role: 'none', title: 'Login', icon: 'login-variant', tooltip: 'Login to see the goodies'},
        {to: '/signup', auth: false, role: 'none', title: 'Sign up', icon: 'account-plus', tooltip: 'Create a new account'},
      ],
    }
  },
  watch: {
  },

  computed: {
    /* menuLocal: function () {
      return this.menu;
    }, */
    compiledLinks: {
      get () {
        let localLinks = [];
        let roles = [];
        if(this.user.role) roles = this.$helpers.findElementInNewArray(
          this.user.role,
          ['user', 'supervisor', 'admin'],
          [['user'], ['user', 'supervisor'], ['user', 'supervisor', 'admin']]
        )

        for (const property in this.links) {
          let link = this.links[property];
          // Hide links that are needing auth
          if(link.auth && this.auth || !link.auth && !this.auth || link.auth === 'both') {
            // Hide Admin links form supervisors
            // localLinks.push(link);
            if(!link.role || roles.includes(link.role) || (!link.auth && !this.auth || link.auth === 'both')) {
              /* if( !link.role || (link.role == this.user.role || ((link.role == "user" && this.user.role == "supervisor") || (link.role == "supervisor" && this.user.role == "admin")))) { */
              localLinks.push(link);
            }
          }
          }
        return localLinks
        //return this.links.filter(x => { if((x.auth && this.auth || !x.auth && !this.auth || x.auth === 'both') && ( !x.admin || x.admin && (this.user.role == 'admin'))) return x})
      }
    },
    menuLocal: {
      get () { return this.menu },
      set () { /* this.$emit('menu', value) */ },
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>