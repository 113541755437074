<template>
  <div class="pb-16 mb-16" v-if="user.role === 'admin'">
    <div class="overline primary--text">Admin</div>
    <v-card-title class="text-h3 justify-center italics error--text">DANGER ZONE!</v-card-title>

    <div class="pb-4">
      <!-- MAKE BACKUP -->
      <v-card-title class="justify-center">Backups</v-card-title>
      <v-card-title class="justify-center">
        <v-btn @click="setBackupName = !setBackupName" :loading="backupInProgress" class="green my-2">
          <v-icon color="white" class="mr-2">mdi-database-plus</v-icon>
          Make backups <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-1">of users & resources</span>
        </v-btn>
        <v-text-field dense height="1em" hide-details style="width:220px;" v-if="setBackupName" filled class="input ml-2 inline-block" placeholder="Set backup Name" type="text" v-model="backupName"></v-text-field>
        <v-btn :disabled="!backupName.length" v-if="setBackupName" @click="triggerBackups(backupName), backupName = '', setBackupName = false" :loading="backupInProgress" class="ml-2 my-2 primary">
          GO
        </v-btn>
      </v-card-title>

      <!-- READ BACKUPS -->
      <ol class="px-0 py-2" v-if="backups && backups['backups'] && backups['backups'].length">
        <li style="list-style:none;" v-for="(backup, x) in backups['backups'].slice().reverse()" :key="x">
          <!-- {{backup}} -->
          <div class="inline-block ellipsis" :title="backup.name" :style="$vuetify.breakpoint.xs ? 'width:100%' : 'width:225px'">
            <span class="grey--text">{{x+1}}. </span>
            <span v-html="starify(backup.name)"></span>
          </div>

          <v-btn icon small class="mr-2 nudge-y--50" :color="reloadBackupConfirmation === backup.date ? 'red' : 'grey'" @click="reloadBackupConfirmation === backup.date ? reloadBackupConfirmation = -1 :reloadBackupConfirmation = backup.date">
            <!-- <v-icon small>mdi-database-outline</v-icon> -->
            <v-icon>mdi-database-clock</v-icon>
          </v-btn>

          <!-- UNIX to date with format... -->
          <div class="grey--text inline-block ellipsis" :class="$vuetify.breakpoint.xs ? 'pb-5' : ''">
            {{ $moment(backup.date).format("dddd, DD. MM. YYYY - HH:mm:ss") }}<!-- 
            -->{{x === 0 ? `, ${$helpers.timeRelativeToNow($moment(backup.date))}, newest` : ''}}<!-- 
            -->{{x === backups.backups.length-1 && x != 0 ? ', oldest' : ''}}
          </div>

          <div class="error white--text bolder darken-1 rounded mb-8 pa-2 text-center" v-if="reloadBackupConfirmation === backup.date">
            <p class="mb-2">
              Really sure to delete current data & reload this backup:
              <br>
              {{backup.name}} @ {{backup.date}}
            </p>
            <div class="text-h4 mb-8 sine20">Hou will not be asked again!</div>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'categories')">
              <v-icon class="mr-2">mdi-file-tree</v-icon> Reload categories
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'productions')">
              <v-icon class="mr-2">mdi-movie</v-icon> Reload productions
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'props')">
              <v-icon class="mr-2">mdi-briefcase</v-icon> Reload props
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'propsTemp')">
              <v-icon class="mr-2">mdi-briefcase-edit</v-icon> Reload propsTemp
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'rental')">
              <v-icon class="mr-2">mdi-swap-horizontal-bold</v-icon> Reload rental
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'rentalUncategorized')">
              <v-icon class="mr-2">mdi-swap-horizontal</v-icon> Reload rental-uncategorized
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'settings')">
              <v-icon class="mr-2">mdi-wrench</v-icon> Reload settings
            </v-btn><br>
            <v-btn color="white" class="mr-2 mb-2 black--text" small @click="reloadBackup(backup.date.trim(), 'tags')">
              <v-icon class="mr-2">mdi-tag-multiple</v-icon> Reload tags
            </v-btn><br>
            <v-btn color="primary"  class="mr-2 mb-2" small @click="reloadBackup(backup.date.trim(), 'users')">
              <v-icon class="mr-2">mdi-account-group</v-icon> Reload users
            </v-btn><br>
          </div>
        </li>
      </ol>
      <div v-else class="pa-1 my-2 red">No backups yet, or page hard reloaded.</div>
    </div>

    <!-- MAINTENANCE MODE -->
    <hr class="mb-3 mt-16" style="border:none; border-top: solid 1px rgba(255,255,255,.25);">
    <v-card-title class="justify-center">Maintenance</v-card-title>
    <v-card-title class="justify-center">
      <v-btn @click="setMaintenance(!settings.maintenance)" :color="settings.maintenance ? 'error--fade' : 'green'">
        {{ settings.maintenance ? 'Turn off Maintenance mode' : 'Turn on Maintenance mode'}}
      </v-btn>
    </v-card-title>

    <p v-if="settings.maintenance" class="my-2">
      Maintenance mode is on.
      <br>
      During maintenance mode (now!), no user is able to login or make changes. Except for you, admin.
    </p>
    <p v-else class="my-2">
      Maintenance mode is off.
      <br>
      During maintenance mode, no user is able to login or make changes. Except for you, admin.
    </p>

  </div>
</template>

<script>
import { db } from '../firebase'

  export default {
    name: 'Admin',
    props: {
      user: Object,
      settings: Object,
    },
    components: {
    },

    data () {
      return {
        success: false,
        somethingWrong: false,
        setBackupName: false,
        backupName: '',
        loading: false,
        backupInProgress: false,
        backups: [],
        reloadBackupConfirmation: -1,
        unsubscribeBackups: [],
      }
    },

    created() {
        // Get dates of backups
        this.unsubscribeBackups = db.collection("backups")
        .doc("backuplist")
        .onSnapshot(doc => {
            let data = doc.data();
            this.backups = data
        });
    },

    computed: {
    },

    beforeDestroy() {
      if(typeof this.unsubscribeBackups === 'function') this.unsubscribeBackups();
    },

    methods: {
      setMaintenance(value) {
        this.$store.dispatch('updateField', {'collection': 'settings', 'document': 'settings', 'field': 'maintenance', 'data': value})
      },

      starify(backupName) {
        // Yes yes kindly fuck off ;)
        return backupName.replace(/\*/g, '<i class="v-icon notranslate mdi mdi-star theme--dark orange--text" style="font-size: 1em;"></i>');
      },

      async triggerBackups(backupName) {
        let currentDate = new Date().toISOString();
        let allBackups = [];
        if(this.backups && this.backups.backups.length) {
          allBackups = [...this.backups.backups, {date: currentDate, name: backupName}];
        } else {
          allBackups = [{date: currentDate, name: backupName}];
        }
        await this.$store.dispatch('updateField', {'collection':'backups', 'document':'backuplist','field':'backups', 'data': allBackups});
       
        this.backupInProgress = true;
        // this.makeBackup('texts', currentDate);  // test
        await this.makeBackup('categories', currentDate);
        await this.makeBackup('productions', currentDate);
        await this.makeBackup('props', currentDate);
        await this.makeBackup('propsTemp', currentDate);
        await this.makeBackup('rental', currentDate);
        await this.makeBackup('rentalUncategorized', currentDate);
        await this.makeBackup('settings', currentDate);
        await this.makeBackup('tags', currentDate);
        await this.makeBackup('users', currentDate);
        this.backupInProgress = false;
      },

      async makeBackup(source, date) {
        console.log(`Start backup for '${source}' => 'backups/${date}'.`);
        this.$store.dispatch('copyCollection', {'source': source, 'target': `backups/${date}/${source}`}).then(() => {
          console.log(`Sucessful created backup for ${source}`);
          this.$toasted.global.success({msg:`Sucessful created backup for ${source}`});
          return;
        }).catch(error => {
          console.log(`Error creating backup for ${source}`);
          console.log(error);
          console.error(error.message);
          this.$toasted.global.error({msg:`Error creating backup for ${source}: "${error.message}"`});
          return;
        });
      },

      async reloadBackup(filename, collection) {
        // delete current resources
        try {
          console.log(`Try to delete old collection if '${collection}'`);
          const documents = await db.collection(collection).get();
          documents.forEach(async (doc)=> {
            // Do NOT delete own document - makes you logout + the rest of the backup stop!
            if(collection === 'users' && doc.id === this.user.uid) {
              console.log("Skipped deletion of your own user file");
              return;
            }
            await db.collection(collection).doc(doc.id).delete().then(function() {
              return true
            }).catch(function(error) {
              throw error;
            });
          })

        } catch (error) {
          console.log(error);
          return;

        } finally {
        // copy old to new
        console.log("copy old to new"); 
        console.log(filename);
        await this.$store.dispatch('copyCollection', {'source': `backups/${filename}/${collection}`, 'target': collection}).then(() => {
          console.log(`Sucessful reloaded backup '${filename}' @ ${collection}`);
          this.$toasted.global.success({msg:`Sucessful reloaded backup ${filename} @ ${collection}`});
          return;
        }).catch(error => {
          console.log(error);
          console.error(error.message);
          this.$toasted.global.error({msg:error.message});
          return;
        });
        this.reloadBackupConfirmation = -1;
        }
      },
    },
  }
</script>
