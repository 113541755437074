<template>
    <div v-if="user.role === 'supervisor' || user.role === 'admin'">
    <div class="overline primary--text">NewProp</div>

    <v-row>
      <!-- EDIT PROP -->
      <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12">
        <v-card class="px-4 py-16" v-if="newProp.images" ref="top">
          <v-btn block color="primary" @click="dialog.mobileEdit = !dialog.mobileEdit; mobileEditStep = 1">
            <v-icon class="mr-2">mdi-briefcase-plus</v-icon>
            {{ $t('Define new prop') }}
          </v-btn>

          <v-dialog
            v-model="dialog.mobileEdit"
            persistent
            fullscreen
            class=""
          >
            <!-- <v-card class="fill-height">
              <v-container fluid class="fill-height">
                <v-row>
                  <v-col class="text-center">
      <div class="overline primary--text">newProp Mobile version</div> -->
                    <MobilePropEdit
                      :key="newProp.id"
                      :auth="auth" :user="user" :settings="settings"
                      :prop="newProp"
                      :selectStep="mobileEditStep"
                      @close="dialog.mobileEdit = !dialog.mobileEdit"
                    ></MobilePropEdit>
                  <!-- </v-col>
                </v-row>
                <v-card-actions class="red fill-width">
                  <v-btn>close</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn>next</v-btn>
                </v-card-actions>
              </v-container>
            </v-card> -->
          </v-dialog>
        </v-card>
      </v-col>
      <v-col v-else cols="12" sm="12" md="7" lg="8" xl="8">
        <v-card class="" v-if="newProp.images" ref="top">
          <div class="overline primary--text pl-4 pt-4">
            {{ path === 'props' ? $t('Edit prop') : $t('Define new prop')}}
          </div>

          <!-- this v-if waits for the initial clearProp() to happen -->
          <!-- <v-card class="pb-3 mb-4" v-if="newProp.images" ref="top"> -->
          <v-card-title>
            <div class="ma-4 absolute top right">
              <!-- /{{ path }} -->
              <span class="caption ">
                {{$t('Article Nr.')}} <v-chip small class="code mr-4 ml-2">{{ newProp.id }}</v-chip>
              </span>
              <v-icon v-if="savedNow" class="sine20" color="green">mdi-content-save-settings</v-icon>
              <v-icon v-else class="op-50" color="grey">mdi-content-save</v-icon>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="pointer mb-2" @click="dialog.categories=!dialog.categories">
              dialog.categories: {{ dialog.categories }}<br>
              <Breadcrumbs classNames="inline" :crumbs="categoryPath"></Breadcrumbs>
              <v-icon small class="ml-2 secondary--text">mdi-pencil</v-icon>
            </div>
            <TextfieldAutotranslate
              tabindex="10"
              :auth="auth" :user="user" :settings="settings"
              :triggerTranslations="triggerTranslations"
              @translated="translationFinished['name'] = $event"
              :data="newProp.name" 
              classNames="mb-2"
              classMainContainer=""
              initialRowHeight="1"
              :dense="true"
              :oneLine="true"
              prefix="item"
              :placeholder="$t('Name of prop')"
            ></TextfieldAutotranslate>

            <TextfieldAutotranslate
              tabindex="20"
              :auth="auth" :user="user" :settings="settings"
              :triggerTranslations="triggerTranslations"
              @translated="translationFinished['description'] = $event"
              :data="newProp.description" 
              classNames="mb-2"
              classMainContainer=""
              initialRowHeight="4"
              :dense="true"
              :oneLine="false"
              prefix="item"
              :label="$t('Description')"
            ></TextfieldAutotranslate>

            tags {{ newProp.tags }}<br>

            <TextfieldAutotranslate
              tabindex="30"
              :auth="auth" :user="user" :settings="settings"
              :triggerTranslations="triggerTranslations"
              @translated="translationFinished['defects'] = $event"
              :data="newProp.defects" 
              classNames="mb-2"
              classMainContainer=""
              initialRowHeight="4"
              :dense="true"
              :oneLine="false"
              prefix="item"
              :label="$t('Defect')"
            ></TextfieldAutotranslate>

            <TextfieldAutotranslate
              tabindex="40"
              :auth="auth" :user="user" :settings="settings"
              :triggerTranslations="triggerTranslations"
              @translated="translationFinished['aiDescription'] = $event"
              :data="newProp.aiDescription" 
              classNames="mb-2"
              classMainContainer=""
              initialRowHeight="4"
              :dense="true"
              :oneLine="false"
              prefix="item"
              :label="$t('AI description of first image')"
            ></TextfieldAutotranslate>

            <v-text-field
              tabindex="40"
              class="mb-2"
              v-model="newProp.note"
              :placeholder="$t('Other notes (internal)')"
              filled
              dense
              hide-details
            ></v-text-field>

            <v-card-text class="px-0">
              <FileUpload
                tabindex="45"
                class="inline"
                type="image/*"
                :target="`props/${newProp.id}`"
                :multiple="true"
                :orderStart="newProp.images.length"
                icon="image-plus"
                iconClasses=""
                :camera="$vuetify.breakpoint.smAndDown"
                buttonClasses=""
                position="right"
                @uploaded="newImagesUploaded($event)"
                @error="$toasted.global.error({msg:$event})"
              >
                {{ $helpers.plural(newProp.images.length, [$t('No images as of yet'), $t('image'), $t('images')]) }}
                {{ generatingThumbnails ? ` [${$t('Generating thumbnails')}..]` : '' }}
              </FileUpload>
              
              <v-btn v-if="newProp.images.length" @click="editImages = !editImages" small class="ml-2">{{ $t('Edit images') }}..</v-btn>

              <Gallery
                :auth="auth" :user="user" :settings="settings"
                :width="$helpers.breakpointChooser($vuetify.breakpoint, editImages ? [100,33.33,25,20,12.5] : [25,12.5,12.5,8.125,6.25])"
                containerClasses="mt-4"
                contain
                :images="newProp.images"
                :edit="editImages"
              ></Gallery>
            </v-card-text>

            <v-text-field
              tabindex="50"
              class="mb-2"
              v-model="newProp.amount"
              :label="$t('In stock')"
              filled
              dense
              hide-details
              append-icon="mdi-plus-circle"
              prepend-inner-icon="mdi-minus-circle"
              @click:prepend-inner="newProp.amount > 0 ? newProp.amount-- : 0"
              @click:append="newProp.amount++"
            ></v-text-field>

            <v-checkbox
              tabindex="60"
              v-model="newProp.ident"
              :label="$t(`Ident article`)"
            ></v-checkbox>

            <v-checkbox
              tabindex="70"
              v-model="newProp.available"
              :label="$t(`Available`)"
            ></v-checkbox>

            <v-checkbox
              tabindex="80"
              v-model="newProp.display"
              :label="$t(`Display`)"
            ></v-checkbox>

            <v-checkbox
              tabindex="90"
              v-model="newProp.archived"
              :label="$t(`Archived`)"
            ></v-checkbox>

            <v-text-field
              tabindex="100"
              class="mb-2"
              v-model="newProp.prices.daily"
              :label="$t('Price daily')"
              filled
              dense
              hide-details
              append-icon="mdi-plus-circle"
              prepend-inner-icon="mdi-minus-circle"
              prefix="CHF"
              :suffix="$t('per day')"
              @click:prepend-inner="newProp.prices.daily >= .5 ? newProp.prices.daily -= .5 : 0"
              @click:append="newProp.prices.daily += .5"
            ></v-text-field>

            <v-text-field
              tabindex="110"
              class="mb-2"
              v-model="newProp.prices.insurance"
              :label="$t('Price insurance')"
              filled
              dense
              hide-details
              append-icon="mdi-plus-circle"
              prepend-inner-icon="mdi-minus-circle"
              prefix="CHF"
              :suffix="$t('if lost')"
              @click:prepend-inner="newProp.prices.insurance >= 16 ? newProp.prices.insurance -= 16 : 0"
              @click:append="newProp.prices.insurance += 16"
            ></v-text-field>

            <!-- STORAGE -->
            <v-combobox
              tabindex="120"
              class="mb-2"
              clearable
              filled
              hide-details
              v-model="newProp.storage.description"
              :items="storageOptions"
              :search-input.sync="searchStorage"
              open-on-clear
              :label="$t('Stored where about?')"
              item-disabled
              :return-object="false"
            >
              <template v-slot:item="{ on, attrs, item }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.disabled"
                  :class="item.disabled ? 'ma-0' : ''"
                  :item-value="'text'"
                >
                  <div v-if="item.disabled" class="secondary--text">
                    {{ $t(item.text) }}:
                  </div>
                  <div v-else class="pl-4">
                    {{ item.text }}
                  </div>
                </v-list-item>
              </template>

              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{$t('No results matching')}} "<strong>{{ searchStorage }}</strong>". {{$t('Press enter to create a new one')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            
            contact Ça tourne {{newProp.contact.id}} {{newProp.contact.name}}<br>

            Is set Master: {{ newProp.setMaster }} <br>
            Is part of set: {{ newProp.set }} <br>
            
            <hr>

            year {{ newProp.year }}<br>

            newProp.dimensions:<br>
              L: {{ newProp.dimensions.l }} &times;
              B: {{ newProp.dimensions.b }} &times;
              H: {{ newProp.dimensions.h }},
              W: {{ newProp.dimensions.weight }}kg
            <br>

            <v-text-field
              tabindex="130"
              class="mb-2"
              v-model="newProp.manufacturer"
              :placeholder="$t('Manufacturer')"
              filled
              dense
              hide-details
            ></v-text-field>

            <v-text-field
              tabindex="140"
              class="mb-2"
              v-model="newProp.source"
              :placeholder="$t('Source')"
              filled
              dense
              hide-details
            ></v-text-field>
            

            <!-- Translated: {{ Object.values(translationFinished).every(value => value === true) }}
            <br> -->
            <v-btn class="mr-2" @click="clearProp()">Reset</v-btn>
            <v-btn class="mr-2" @click="dialog.tags=!dialog.categories">Set tags</v-btn>
            <v-btn class="mr-2" @click="triggerTranslations = $helpers.createUid();">triggerTranslations</v-btn>

          </v-card-text>
          <v-card-actions>
            <a
              v-if="user.role === 'admin' && newProp.id"
              target="_blank"
              class="mx-2 no-underline"
              :title="`Open Firebase document of this temp prop`"
              :href="`https://console.firebase.google.com/project/dbcatourne/firestore/data/~2F${path}~2F${newProp.id}`"
            >
              <v-icon class="orange--text">mdi-firebase</v-icon>
            </a>

            <a
              v-if="user.role === 'admin' && newProp.id"
              target="_blank"
              class="mx-2 no-underline"
              :title="`Open Firebase Storage of these images`"
              :href="`https://console.firebase.google.com/project/dbcatourne/storage/dbcatourne/files/~2Fprops~2F${newProp.id}`"
            >
              <v-icon class="orange--text">mdi-library-shelves</v-icon>
            </a>

            <v-spacer></v-spacer>

            <v-btn @click="nextProp(true)" tabindex="150" :color="path === 'props' ? 'primary' : ''">
              <v-icon class="mr-2">{{ path === 'propsTemp' ? 'mdi-content-save-edit' : 'mdi-content-save' }}</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">
                {{ path === 'propsTemp' ? $t('Temp save & new') : $t('Save & new')}}
              </span>
              <span v-else>
                {{ path === 'propsTemp' ? $t('Temp') : $t('Save')}}
              </span>
            </v-btn>

            <v-btn v-if="path === 'propsTemp'" @click="saveFinalProp(newProp)" color="primary"
            tabindex="160">
              <v-icon class="mr-2">mdi-content-save-move</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{$t('Save final & new')}}</span>
              <span v-else>{{$t('Final')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" class="" :class="$vuetify.breakpoint.mdAndUp ? 'pl-0' : ''" sm="12" md="5" lg="4" xl="4">
        <!-- CURRENTLY BEING EDITED PROPS -->
        <v-row>
          <v-col cols="12" sm="6" md="12" lg="12" xl="12">
            <v-card class="">
              <div class="overline primary--text pl-4 pt-4">
                {{$t('Temp saves')}}
              </div>
              <div class="mx-4 italics" v-if="propsTemp.length">
                {{ $helpers.plural(propsTemp.length, [$t('prop are currently being edited'), $t('props are currently being edited')]) }}.
              </div>
              <!-- does not wait for propsTemp to load? -->
              <Props
                :auth="auth" :user="user" :settings="settings"
                edit
                listView
                :width="[]"
                :key="propsTempLoaded"
                :props="propsTemp"
                :noPropsHint="`${$t('No props are currently being edited.')} 🥳`"
                :limitReachedHint="$t('There may be more elements to this.')"
                highlightSelected
                :select="newProp.id"
                @clicked="loadProp('propsTemp', $event)"
                @editProp="loadProp('propsTemp', $event)"
                @deleteProp="deleteProp('propsTemp', $event.item, false, $event.force)"
                @mobileEdit="dialog.mobileEdit = true; loadProp('propsTemp', $event.item); mobileEditStep = $event.step;"
              ></Props>
            </v-card>
          </v-col>

          <!-- LAST 10 EDITED PROPS -->
          <v-col cols="12" sm="6" md="12" lg="12" xl="12">
            <v-card class="">
              <div class="overline primary--text pl-4 pt-4">
                {{$t('Last 10 finalized')}}
              </div>
              <Props
                :auth="auth" :user="user" :settings="settings"
                :edit="user.role !== 'user'"
                listView
                :width="[]"
                path="props"
                :noPropsHint="`${$t('No props exist :(')}`"
                :limit="10"
                :limitReachedHint="$t('There may be more elements to this.')"
                highlightSelected
                :select="newProp.id"
                @clicked="loadFinalizedDocument('props', $event)"
                @editProp="loadFinalizedDocument('props', $event)"
                @deleteProp="deleteProp('props', $event.item, false, $event.force)"
                @mobileEdit="dialog.mobileEdit = true; loadFinalizedDocument('props', $event.item); mobileEditStep = $event.step;"
              ></Props>

              <!-- <v-card-text v-if="props.length">
                <div v-for="item in props" :key="item.id">
                  <v-list-item three-line class="px-4 mb-4 rounded" :class="newProp.id === item.id ? 'primary' : ' grey darken-4'">
                    {{ item.name[$i18n.locale] }}
                    -
                    {{ $helpers.timeRelativeToNow($moment($helpers.fbTimeToString(item.editedBy.on))) }}

                    {{$t('Article Nr.')}}: <v-chip small class="code">{{ item.id }}</v-chip>

                    <span v-if="item.aiDescription.en.length" class="caption">
                      <v-icon color="secondary" class="pop-in-fancy">mdi-robot</v-icon>
                      {{$t('AI text available for the human touch')}}
                    </span>
                    </v-list-item>
                  </div>
              </v-card-text>
              <v-card-text>
                <v-btn block color="secondary" to="/collection">{{ $t('More') }}..</v-btn>
              </v-card-text> -->
              <!-- <pre>{{ props }}</pre> -->
            </v-card>
          </v-col>

          <!-- RELAXATION -->
          <v-col class="op-50" cols="12" sm="6" v-if="$vuetify.breakpoint.mdAndUp && propsTemp.length < 8" md="12" lg="12" xl="12">
          <!-- <v-col class="op-50" cols="12" sm="6" v-if="$vuetify.breakpoint.mdAndUp && (propsTemp.length + props.length) < 8" md="12" lg="12" xl="12"> -->
            <v-card class="pa-4 relaxation">
              <div class="overline primary--text">
                {{$t('Space intentionally left blank')}}
              </div>
              <span class="sine20 white--text">
                {{$t('Room for activity & relaxation')}}
              </span>
              <v-container fill-height >
                <v-row justify="center">
                    <v-col style="text-align: center;">
                      <v-icon color="white" class="op-12" style="font-size:12em">mdi-brain</v-icon>
                    </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>



    <v-dialog
      v-model="dialog.categories"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="750"
    >
      <v-card>
        <v-card-title class="primary--text grey darken-4">
          <v-icon class="primary--text mr-2">mdi-file-tree</v-icon>
          {{ $t('Set category')}}
        </v-card-title>
        <v-card-text>
          <Categories :edit="false" @clickedCategory="setCategory($event)" :auth="auth" :user="user" :settings="settings"></Categories>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog.categories=!dialog.categories">Set category</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    </div>
</template>

<script>
import { db } from "../firebase";
/* import LanguageSwitcher from "./LanguageSwitcher.vue"
import BrightnessSwitcher from "./BrightnessSwitcher.vue" */
import Categories from "../components/Categories.vue"
import FileUpload from "../components/FileUpload.vue"
import Breadcrumbs from "../components/Breadcrumbs.vue"
import TextfieldAutotranslate from "../components/TextfieldAutotranslate.vue"
import Gallery from "../components/Gallery.vue"
import Props from "../components/Props.vue"
import MobilePropEdit from "../components/MobilePropEdit.vue"


export default {
  name: 'NewProp',
  props: {
    auth: Boolean,
    user: Object,
    settings: Object
  },
  components: {
    Categories,
    TextfieldAutotranslate,
    Breadcrumbs,
    FileUpload,
    Gallery,
    Props,
    MobilePropEdit,
  },
  data() {
    return {
      newProp: {},
      path: 'propsTemp',
      categoryPath: [],
      unsubscribepropsTemp: [],
      propsTempLoaded: 'initial',
      initialEmptyPropMd5: '',
      // unsubscribeProps: [],
      unsubscribeStorageOptions: [],
      saveTimeout: null,
      propsTemp: [],
      // props: [],
      storageOptions: [],
      storageOptionsTitles: [],
      dialog: {
        categories: false,
        tags: false,
        mobileEdit: false,
      },
      mobileEditStep: 1,
      translationFinished: {
        'name': false,
        'description': false,
        'defects': false,
        'aiDescription': false,
      },
      editImages: false,
      loading: false,
      savedNow: false,
      generatingThumbnails: false,
      waitForLanguageConfirmation: false,
      confirmTranslation: false,
      triggerTranslations: '',
      searchStorage: '',
      dontSaveEmptyTemp: false,
    }
  },

  mounted() {
    // TODO: Send supervisor / admin to specialized prop catcher tool
    // if(this.$vuetify.breakpoint.smAndDown) this.$router.push({ path: '/newPropMobile' });
  },

  async created() {
    await this.getpropsTemp();
    this.getStorageOptions();
    this.clearProp();
    // await this.getProps();

    // Get ID from localstorage to resume where you left off
    let storedId = this.$helpers.readLocalStorage('storedId');

    if (this.$route.params.path && this.$route.params.id) {
      // TODO:
      console.log("do something with the url and ignore / reset localStorage ?");
      console.log("path by URL: ", this.$route.params.path);
      console.log("id by URL: ", this.$route.params.id);

    } else if(storedId) {  // && this.propsTemp[storedId]
      // figure out which path???
      this.path = this.$helpers.readLocalStorage('path');

      if(this.path === 'props') {
        // load this one specific, finalized file
        this.newProp.id = storedId;
        this.loadFinalizedDocument('props', this.newProp);

      } else {
        // console.log("get data from propsTemp in DB!");
        // this does not work if propsTemp is not loaded correctly
        // this.newProp = this.$helpers.findKey(this.propsTemp, 'id', storedId, 'object');
        this.newProp.id = storedId;
        this.getpropsTemp();  // again because now this.newProp.id is set?
      }
    } else {
      /* CREATE + SAVE ONLY WHEN SOMETHING IS ENTERED? DOES THE WATCHER DO THIS! */
      this.newProp.id = this.$helpers.createArticleNumber(8);  // create a randomly, arbitrary number
      // TODO: only save first time if any changes happen! see watcher!
      this.saveNewProp('propsTemp', this.newProp);
      this.$helpers.writeLocalStorage('storedId', this.newProp.id);
      this.$helpers.writeLocalStorage('path', 'propsTemp');
    }
    //this.newProp = this.propsTemp;
  },

  beforeDestroy() {
    /// FIXME: really necessary? or just keep it?
    if (typeof this.unsubscribepropsTemp === "function") this.unsubscribepropsTemp();
    if (typeof this.unsubscribeProps === "function") this.unsubscribeProps();
    if (typeof this.unsubscribeStorageOptions === "function") this.unsubscribeStorageOptions();

    // If new temp prop was not changed at all, delete this.newProp.id
    if(this.path === 'propsTemp') {
      // Ok wow this is shit
      let checkChanges = JSON.parse(JSON.stringify(this.newProp));
      //Equalize auto-changing data to check against current status
      checkChanges.id='';
      // because it has spent quality time in FB data, time stamp would be changed, somehow
      checkChanges.createdBy.on='';
      if(this.initialEmptyPropMd5 === this.$helpers.md5(JSON.stringify(checkChanges))) {
        console.log("destroy the unchanged prop");

        this.deleteProp('propsTemp', this.newProp, false, true);
        // reset local storage PATH and ID
        localStorage.removeItem('storedId');
        localStorage.removeItem('path');
      }
    }
  },

  watch: {
    newProp: {
      // eslint-disable-next-line no-unused-vars
          handler: function(newValue) {
            // Set changedAnything to true to sign taht no cahnges where made to safely delete an empty Temp prop
            // this.changedAnything = true;

            // If user enters number, its a string
            if(typeof this.newProp.prices.daily === 'string') this.newProp.prices.daily = parseFloat(this.newProp.prices.daily);
            if(typeof this.newProp.prices.insurance === 'string') this.newProp.prices.insurance = parseFloat(this.newProp.prices.insurance);

            clearTimeout(this.saveTimeout); // Clear any existing timeout
            this.saveTimeout = setTimeout(() => {
              // console.log("timeout", newValue);
              this.editProp(this.path, this.newProp)
            }, 1500); // Set a new timeout for 150ms
          },
          deep: true
      }
  },

  methods: {
    getpropsTemp() {
      // Terminate earlier snapshots if existing
      if (typeof this.unsubscribepropsTemp === "function") {
        this.unsubscribepropsTemp();
      }
      this.unsubscribepropsTemp = db
        .collection(this.path)
        // .orderBy("editedBy.on", "desc")
        .orderBy("createdBy.on", "desc");

      this.unsubscribepropsTemp = this.unsubscribepropsTemp.onSnapshot(
        (querySnapshot) => {
          let newData = [];
          querySnapshot.forEach((doc) => {
            let f = doc.data();
            // f.id = doc.id;
            if(f.id === this.newProp.id) this.newProp = f;
            // populate
            // f.category = this.categories[f.category];
            newData.push(f);
          });
          this.propsTemp = newData;
          // to trigger rendering of component only first time
          // Without this, hmmm does reload ALL DOCUMENTS EVERY TIME SOMETHING IS SAVED
          // with this, its not reactive anymore
          // if(this.propsTempLoaded === 'initial') this.propsTempLoaded = "loaded-"+new Date();
          this.propsTempLoaded = "loaded-"+new Date();

        }
      );
    },

    getStorageOptions() {
      if (typeof this.unsubscribeStorageOptions === "function") {
        this.unsubscribeStorageOptions();
      }
      this.unsubscribeStorageOptions = db.collection("settings")
      .doc("storage")
      .onSnapshot(doc => {
        let data = doc.data();
        let options = [{header:this.$t('Type anything or select something below that is already entered by someone.')}];
        // eslint-disable-next-line no-unused-vars
        Object.keys(data).forEach((value, index) => {
          options.push({divider:true}, {text: value, value: value, disabled:true});
          this.storageOptionsTitles.push(value);
          // eslint-disable-next-line no-unused-vars
          data[value].forEach((value, index) => {
            options.push({text: value, value: value});
          });
        });
        this.storageOptions = options;
      });
    },

    setCategory(categoryId) {
      this.dialog.categories = false;
      console.log("received cat id from dialog: ", categoryId)
      // TODO: ALLOW FOR MULTIPLE CAT? ==> this.newProp.categories.push(categoryId);
      this.newProp.categories = [categoryId];
    },

    clearProp() {
      // ?????? this.path = 'propsTemp';
      let listOfLanguages = this.$i18n.availableLocales.reduce((acc,curr)=> (acc[curr]='',acc),{});
      this.newProp = {
        id: '',
        name: listOfLanguages,
        categories: [],
        tags: [],
        description: listOfLanguages,
        defects: listOfLanguages,
        aiDescription: listOfLanguages,
        note: "",  // For internal things, or something to be displayed on the invoice PDF or during collection
        amount: 1,
        ident: true,  // is this one very specific object or a collection of, say, pencils?
        images: [],
        prices: {
          daily: 0.0,
          insurance: 0.0,
        },
        storage: {
          description: '',  // Describe where the object is
          shelf: '',  // For later use
        }, 
        contact: {  // who to call for questions?
          id: this.user.uid,
          name: this.user.name,
        },
        available: true,  // maybe its broken and not ready for rental yet
        display: true,    // if its part of a set or other reasons, don't display
        setMaster: false, // is the only article that shows up for this set
        set: [],          // contains art-nr from other props which are linked
        archived: false,  // do not display, only in archive
        deleted: false,   // do not display anywhere except for admins
        year: new Date().getFullYear(),  // from which year is the object, probably?
        dimensions: {
          l: 0,
          b: 0,
          h: 0,
          weight: 0,
        },
        manufacturer: '',  // Original maker of the object
        source: '',  // Where it was bought, Url, etc
        createdBy: {
          id: this.user.uid,
          name: this.user.name,
          on: '',  // to leave the md5 hash alone for comparing anchanged empty newProps,s et this laters..
        },
        editedBy: {
          id: '',
          name: '',
          on: 0,
        },
      }
      this.initialEmptyPropMd5 = this.$helpers.md5(JSON.stringify(this.newProp));
      this.newProp.createdBy.on = new Date();  // ..here.
    },

    async loadFinalizedDocument(path, data) {
      if (typeof this.unsubscribeEditProp === "function") {
        this.unsubscribeEditProp();
      }
      this.unsubscribeEditProp = await db.collection(path)
      .doc(data.id)
      .onSnapshot(doc => {
        let data = doc.data();
        this.newProp = data;
      });
      this.path = path;
      this.$helpers.writeLocalStorage('storedId', data.id);
      this.$helpers.writeLocalStorage('path', path);
      this.$helpers.scrollTo(this, 'top', 'start');
    },

    loadProp(path, data) {
      this.newProp = data;
      this.path = path;
      this.$helpers.writeLocalStorage('storedId', this.newProp.id);
      this.$helpers.writeLocalStorage('path', this.path);
      this.$helpers.scrollTo(this, 'top', 'start');
    },

    async saveNewProp(path, data) {
      console.log(`save to ${path}`, path, data);

      const userDocRef = db.collection(path).doc(data.id);
      const doc = await userDocRef.get();
      if (!doc.exists) {
        await db.collection(path).doc(data.id).set(data).then(async function() {
          console.log("Saved new document", data.id);
          return true;
        }).catch(async function(error) {
          console.log("error", error);
          throw error;
        });
      } else {
          // IF FILE ALREADY EXISTS - reset ID and try again
          // FIXME: this fucks with the bucket path? old images may have an old folder. might work but chaos for exporting!  
          console.log(`Document ${data.id} already exists - make new id and tray again`);
          data.id = this.$helpers.createArticleNumber(8);  // reset ID and try again
          await this.saveNewProp(path, data);
      }
    },

    async newImagesUploaded(data) {
      this.generatingThumbnails = true;
      // TODO: this shit
      // await until _large, _icon and _thumbnail exists? show loader
      setTimeout(() => {
        this.newProp.images.unshift(data);
        this.generatingThumbnails = false;
      }, 5000);
    },

    async editProp(path, data) {
      // Show some icon that it is saving
      this.savedNow = true;


      //// FIXME do the translation wait game


      // console.log(`edited ${path}`, path, data);
      let success = await db.collection(path).doc(data.id).update(data).then(async function() {
          return true
        }).catch(function(error) {
          console.error(error)
          return null;
        });

      if(!success) {
        // FIXME: IIIIK sometime after deletion etc. file is not available anymore.
        console.log("cannot find file - create new");
        this.saveNewProp(path, data);
      }

      // Hide icon that it is saving
      setTimeout(() => {
        this.savedNow = false;
      }, 2000);
    },

    async saveFinalProp(data) {


      //// FIXME do the translation wait game


      console.log(data);
      // Save data.id in props
      data.editedBy = {
        id: this.user.uid,
        name: this.user.name,
        on: new Date(),
      }
      await this.saveNewProp('props', data);

      // Delete data.id in propsTemp
      await this.deleteProp('propsTemp', data, true)

      this.$toasted.global.success({msg:`Saved to database!`});
      localStorage.removeItem('storedId');
      localStorage.removeItem('path');
      this.nextProp();
    },

    async deleteProp(path, data, isMoving=true, force=false) {
      // FIXME: If last prop is deleted while it is being open, same doc with same ID gets created!
      // Fixme: if deleted prop that is currently being edited, reload newProp and set new ID!
      if(!isMoving && this.newProp.id === data.id && !force) {
        this.$toasted.global.error({msg:this.$t(`Sorry, cannot delete the prop you're working on right now!`)});
        return;
      }

      if(path === 'props' && !isMoving && !force) {
        console.log("Editing finalized prop, set deleted=true if not forced.");
        data.deleted = !data.deleted;
        await db.collection(path).doc(data.id).update(data).then(async function() {
          return true
        }).catch(function(error) {
          console.error(error)
          return null;
        });
        this.$toasted.global.success({msg:this.$t(`Deleted Prop (still available for admins/suopervisors)`)});
        return;
      }

      await db.collection(path).doc(data.id).delete().then(function() {
        return true
      }).catch(function(error) {
        throw error;
      });

      // If its not moviing to "props", don't show toast / delete images when moved from "propsTemp" from "props" it looks stressful
      if(!isMoving) {
        // Remove all images in "props/{id}"
        if(data.images) {
          for (const image of data.images) {
            // FIXME: Somehow, this files are not found even though the images are available under this URL- ...
            this.$store.dispatch('deleteFile', image.url);
            // Tries to delete any thumbnails. If it fails (eg. for .svgs which don't have thumbnails), no matter.
            this.$store.dispatch('deleteFile', this.$helpers.getImageVariant(image.url, 'large'));
            this.$store.dispatch('deleteFile', this.$helpers.getImageVariant(image.url, 'thumb'));
            this.$store.dispatch('deleteFile', this.$helpers.getImageVariant(image.url, 'icon'));
            this.$toasted.global.success({msg:this.$t(`Deleted Temp Prop + images for real`)});
          }
        } else {
          this.$toasted.global.success({msg:this.$t(`Deleted Temp Prop for real`)});
        }
      }
    },

    nextProp(saveCurrent=false) {
      // FIXME: if saved already finalized, change editedBy.on / .by !
      if(saveCurrent) {
        this.editProp(this.path, this.newProp)
      }
      this.clearProp();
      // FIXME?
      this.path = 'propsTemp'
      this.newProp.id = this.$helpers.createArticleNumber(8);
      this.saveNewProp('propsTemp', this.newProp);
      this.$helpers.writeLocalStorage('storedId', this.newProp.id)
      this.$helpers.writeLocalStorage('path', 'propsTemp')
    }
  },

  computed: {
    /* menuLocal: {
      get () { return this.menu },
      set (value) { this.$emit('menu', value) },
    }, */
  },
};
</script>

<style scoped>
.relaxation {
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	/* height: 100vh; */
}

@keyframes gradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}
</style>