<template>
  <v-menu offset-y close-on-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-tag-plus</v-icon>
          {{ $t(categories[selected]) }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in categories"
          :key="index"
          @click="$emit('select', index)"
        >
          <v-list-item-title class="pointer">{{ $t(item) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
  export default {
    props: {
      auth: Boolean,
      user: Object,
      settings: Object,
      categories: Object,
      selected: String,
    },
    components: {
    },
    data() {
      return {
      }
    },
    watch: {
    },

    methods: {
     
    },
    created() {
     
    }
  };
</script>
